.MailIns-table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.MailIns-pools-table {
  width: 100%;
  max-width: 200px;
  text-align: center;
  border-collapse: collapse;
  font-size: 12px;
}

.MailIns-pools-table th {
  background-color: #1E3A8A; /* Azul profundo */
  color: white;
  font-weight: bold;
  padding: 8px;
}

.MailIns-pools-table td {
  background-color: #F4F7FC; /* Fondo claro para las celdas */
  color: #6B7280; /* Gris elegante para el texto */
  padding: 8px;
  border: 1px solid #D1D5DB; /* Gris claro para las líneas de separación */
}

.MailIns-pools-table tr:nth-child(even) td {
  background-color: #E5E7EB; /* Color alterno para filas pares */
}

.MailIns-pools-table tr:hover td {
  background-color: #FBBF24; /* Amarillo vibrante al hacer hover */
  color: #1E3A8A; /* Azul profundo para texto en hover */
}

