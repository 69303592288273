@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}


.tableroPresale{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0px 0;

}


.wrappPresale {
 position: inherit ;
 padding-top: 2em;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 100%));
  gap: 2rem;
  justify-content: center;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 10vh;
 
}



 .wrapp .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0px 0;
}

.container .card {
  position: relative;
  min-width: 260px;
  height: 360px;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.2),
  inset -4px -4px 15px rgba(255, 255, 255, 0.1),
  4px 4px 15px rgba(0, 0, 0, 0.3), -4px -4px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 8px;
  transition: 0.5s;
}




.container .card:nth-child(1) .box .content input {
  background: #2196f3;
}

 .container .card:nth-child(2) .box .content input {
  background: #e91e63;
}

 .container .card:nth-child(3) .box .content input {
  background: #FF0000;
}

.container .card:nth-child(4) .box .content input {
  background: #FF0000;
}

.container .card:nth-child(5) .box .content input {
  background: #FF0000;
}

.container .card:nth-child(6) .box .content input {
  background: #FF0000;
}

.container .card:nth-child(7) .box .content input {
  background: #FF0000;
}


.preventa {
  padding-top: 1em;
  text-shadow: 2px 2px 4px #FF0000;
}



 .container .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}


 .container .card .box:hover {
  transform: translateY(-50px);
}

 .container .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

 .container .card .box .content {
  padding: 20px;
  text-align: center;
}



 .container .card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

 .container .card .box .content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

 .container .card .box .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}




 .container .card .box .content input{
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}


 .container .card .box .content input:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}