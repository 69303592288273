.salud {
  display: flex;
  align-items: center;
  align-self: flex-end;
  width: 20vw;
  margin-bottom: 1rem; /* Ajuste para espaciado */
}

.barra-container {
  width: 100%;
  position: relative;
}

.barra {
  display: flex;
  align-self: center;
  height: 2rem;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  padding-left: 0.5rem;
  background-color: #ddd; /* Color de fondo para la barra */
}

.svg {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.barra p {
  margin: 0;
  padding: 0 0.5rem;
  color: #222;
}

.barra span {
  position: absolute;
  right: 0.5rem;
  white-space: nowrap;
  color: #222;
}

.salud svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: red;
}

/* Dispositivos pequeños (móviles en orientación vertical, 320px - 480px) */
@media only screen and (min-width: 280px) and (max-width: 480px) {
  .salud {
    width: 50vw;
  }

}

/* Dispositivos medianos (tabletas en orientación vertical, 481px - 768px) */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  .salud {
    width: 30vw;
  }
}

/* Dispositivos grandes (tabletas en orientación horizontal y pantallas pequeñas de escritorio, 769px - 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .salud {
    width: 25vw;
  }
}

/* Dispositivos extra grandes (pantallas de escritorio grandes, 1025px en adelante) */
@media only screen and (min-width: 1025px) and (min-width: 2525px) {
  .salud {
    width: 5vw;
    /*align-self: flex-end;*/
  }

  .barra-container {
  width: 70%;
  position: relative;
  padding-left: 15vw;
}
}
