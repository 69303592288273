/* FaseGanadores.css */

.fases-ganadores {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.fases-ganadores h3 {
  text-align: center;
  margin-bottom: 20px;
}

.grupo-ganadores {
  margin-bottom: 30px;
  max-width: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
}

.grupo-ganadores-card {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;

}

.equine-link {
  text-decoration: none;
  color: inherit;

}

.equine-card {
  background-color: #167CBB;

  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.equine-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.equine-card span {
  display: block;
  margin-bottom: 10px;
}

button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .grupo-ganadores-card {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

@media (max-width: 480px) {
  .grupo-ganadores-card {
    grid-template-columns: 1fr;
  }
}





..toggle-visibility-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.ojo-cerrado {
  position: relative;
  width: 40px; /* Ancho del ojo */
  height: 20px; /* Alto del ojo */
  border-radius: 10px; /* Bordes redondeados para simular el ojo */

}

.ojo-cerrado::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 80%;
  height: 4px; /* Grosor de la línea del ojo */
  background-color: #333; /* Color del ojo */
  border-radius: 20px; 
}

.ojo-cerrado .pestana {
  position: absolute;
  background-color: #333; /* Color de las pestañas */
}

.pestana1 {
  width: 2px;
  height: 10px; /* Altura de la pestaña */
  left: 1px; /* Posición de la pestaña */
  top: -0px; /* Ajustar la posición vertical */
}

.pestana2 {
  width: 2px;
  height: 10px;
  left: 5px; /* Posición de la pestaña */
  top: -1px; /* Ajustar la posición vertical */
}

.pestana3 {
  width: 2px;
  height: 10px;
  right: 15px; /* Posición de la pestaña */
  top: -0px; /* Ajustar la posición vertical */
}

.pestana4 {
  width: 2px;
  height: 10px;
  right: 10px; /* Posición de la pestaña */
  top: -1px; /* Ajustar la posición vertical */
}



.grupo-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.grupo-header h1 {
  margin: 0; /* Elimina el margen predeterminado del h1 */
}



.arrow-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px; /* Tamaño de la flecha */
}

.arrow-button:hover {
  color: #007BFF; /* Color al pasar el mouse */
}




