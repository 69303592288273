/* Estilos globales */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

/* Modal overlay */
.modalMail-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 200;
    padding: 0.5rem;
}

/* Modal content */
.modalMail-content {
    background: #fff;
    border-radius: 8px;
    width: 85%;
    max-width: 500px; /* Reducido para un modal más compacto */
    max-height: 70vh; /* Ajusta la altura máxima */
    position: relative;
    padding: 0.5rem; /* Reducido padding */
    box-sizing: border-box;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
    transform: rotate(0.5deg); /* Simula la inclinación de una carta */
    overflow: auto;
    border: 1px solid #ddd; /* Bordes suaves como papel */
    background: linear-gradient(to bottom, #fafafa 0%, #f4f4f4 100%); /* Efecto de papel */
    animation: openModal 0.5s ease-out; /* Animación de apertura */
    margin: 0.5rem; /* Asegura que no toque los bordes de la pantalla */
}

/* Animación para el modal */
@keyframes openModal {
    from {
        transform: scale(0.8) rotate(-2deg);
        opacity: 0;
    }
    to {
        transform: scale(1) rotate(0.5deg);
        opacity: 1;
    }
}

/* Modal header */
.modalMail-header {
    display: flex;
    justify-content: flex-end;
}

/* Close button */
.close-button {
    background: transparent;
    border: none;
    font-size: 1.2rem; /* Tamaño más pequeño */
    cursor: pointer;
}

/* Modal body */
.modalMail-body {
    padding: 0.5rem; /* Reducido padding */
    overflow: auto;
}

/* Letter container */
.letter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
}

/* Letter text */
.modalMail-body .letter-text {
    font-size: 0.8rem; /* Tamaño más pequeño */
    line-height: 1.4;
    color: #333333;
    margin: 0.5rem 0; /* Margen más pequeño */
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    padding: 0.5rem; /* Añade padding interno */
    background: rgba(255, 255, 255, 0.9); /* Fondo semitransparente */
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.05); /* Sombras internas suaves */
}

/* Estilo para listas en el modal */
.modalMail-body ol{
  background-color:#007bff ;
    font-size: 1rem;

} .modalMail-body li {
    color: #e0e0e0; /* Color del texto */
}

/* Estilo para listas en el modal */
.modalMail-body li {
    font-size: 0.8rem; /* Tamaño de fuente más pequeño */
    margin-bottom: 0.5rem; /* Margen más pequeño */
}

/* Action button */
.action-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 0.9rem; /* Tamaño más pequeño */
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 80%;
    max-width: 200px; /* Ajusta el tamaño del botón */
    margin-top: 0.5rem;
}

.action-button:hover {
    background-color: #0056b3;
}

/* Añadir un sello o detalle de carta */
.sello {
    position: absolute;
    top: 10px; /* Ajusta la distancia desde el borde superior */
    left: 10px; /* Ajusta la distancia desde el borde izquierdo */
    background-color: #167CBB;
    padding: 8px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    color: white;
    font-weight: bold;
    transform: rotate(-15deg);
    animation: bounce 0.6s ease-in-out;
    z-index: 300;
}

.sello {
    position: absolute;
    top: 0.5rem; /* Ajusta la posición según lo necesites */
    left: 0.5rem; /* Ajusta la posición según lo necesites */
    background-image: url(https://www.blockhorseracing.com/image/logo/logoBlock.png);; /* Ruta a la imagen */
    background-size: contain; /* Ajusta el tamaño de la imagen dentro del div */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    background-position: center; /* Centra la imagen dentro del div */
    width: 3.5rem; /* Ajusta el tamaño del div según la imagen */
    height: 3.5rem; /* Ajusta el tamaño del div según la imagen */
    padding: 0.5rem;
    margin: 0.2rem;
    transform: rotate(-15deg); /* Si quieres mantener el ángulo de rotación */
    z-index: 300; /* Asegúrate de que esté por encima del contenido */
}


.error-message {
  font-size: 14px; /* Tamaño de la fuente */
  margin-top: 10px; /* Espaciado superior */
  padding: 10px; /* Espacio interno alrededor del mensaje */
  border: 1px solid #ff4d4d; /* Borde rojo oscuro */
  background-color: #ff4d4d; /* Fondo rojo oscuro */
  border-radius: 5px; /* Bordes redondeados */
  max-width: 200px; /* Limitar el ancho del mensaje a 200px */
  word-wrap: break-word; /* Asegura que el texto largo se rompa en varias líneas */
  word-break: break-all; /* Permite que las palabras largas también se dividan */
  line-height: 1.5; /* Espacio entre líneas para mayor legibilidad */
}


/* Animación de rebote para el sello */
@keyframes bounce {
    0%, 100% {
        transform: translateY(0) rotate(-15deg);
    }
    50% {
        transform: translateY(-10px) rotate(-15deg);
    }
}
