table.equinos{
    border-collapse: separate;
    border-spacing: 0.5em;
    border-radius: 1em;
    overflow: hidden;
    background-color: transparent;
}

.equinos .coin{
  width: 1em;
  height: 1em;
}


.equino-imagen{
    width: 5em;
    height: 5em;
    border-radius: 5px;
    object-fit: cover;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}




.equino-imagen.hovered {
  transform: scale(1.5); /* Ejemplo: se agranda la imagen en un 20% */
}




/* Contenedor principal del modal */


/* Tabla de equinos */
.equinos {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;
  font-size: 0.9rem;
  color: #fff;
}

.equinos th, .equinos td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.equinos th {
  background-color: #444;
  color: white;
}

/* Iconos y textos alineados horizontalmente */
.icon-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.icon-text img {
  width: 1.5rem;
  height: 1.5rem;
}

/* Imagen del equino */
.equine-image {
  max-width: 100px;
  height: auto;
}

/* Responsive adjustments */
@media only screen and (max-width: 480px) {
  .modal-container-race-results {
    width: 95%;
    padding: 0.5rem;
  }

  .equinos {
    font-size: 0.8rem;
  }

  .equinos th, .equinos td {
    padding: 6px;
  }

  .icon-text img {
    width: 1rem;
    height: 1rem;
  }

  .equine-image {
    max-width: 80px;
  }
}

@media only screen and (max-width: 768px) {
  .equinos {
    font-size: 0.85rem;
  }

  .equinos th, .equinos td {
    padding: 7px;
  }

  .icon-text img {
    width: 1.2rem;
    height: 1.2rem;
  }

  .equine-image {
    max-width: 90px;
  }
}
