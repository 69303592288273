/*.cart-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
}

.cart-containera {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  width: 70%;
}*/
/*
.selected-items {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
}*/

.button-container {
 
  display: inline-flex;
  justify-content: space-between;
  padding: 10px;
  width: 70%;}


.buy-button-container {
  margin-top: 10px;
}


 .market-img {
  position: relative;
  border-radius: 8px;
  border: 3px solid #12897A;
  max-width: 100%;
  background-size: cover;
}



.market-img img {
  width: 100%;
 border: 2px solid transparent;
 top: 2px;

 
 }


.utility-group-modal {
  position: relative;
  min-width: 90%;
  min-height: 90%;
  overflow-x: auto;
  white-space: wrap; /* Evita el salto de línea horizontal */
  display: flow;
  background-color: #222;
}



.imagen-modal-market{
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  padding-top: 6%; /* Otros estilos para el contenedor si los necesitas */

}

.imagen-modal-market h3{
position: absolute;
top:0px  ;
}

img.utility-nft-image {
  width: 30%; 
  height: 30%;
  display: flex;
  align-content: center;
   }

.utility-group-modal .item-market {
 display: inline-block;
  padding: 10px; /* Espacio interno dentro de cada celda */
  border: 1px solid #ccc;  /*Borde alrededor de cada celda */
  border-radius: 5px; /* Bordes redondeados */
  margin: 0.3em; /* Espacio entre las celdas */
  color: white;
  
  max-width: 80%; /* Limita el ancho del elemento de texto */
  /*white-space: nowrap;  Evita el salto de línea del texto */
  overflow: hidden; /* Oculta el exceso de contenido */
  text-overflow: ellipsis; 
}


.utility-group-modal .item-market span {
  display: flow;
  margin-right: 10px;
  color: white;
}


.marketo  {
  color: gold;
}


.gold-text {
  color: gold; /* Puedes ajustar el valor "gold" según tus preferencias */
}
.utility-group-modal .item button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.utility-group-modal .item button:hover {
  background-color: #0056b3;
}



































/*














@media only screen and (max-width: 600px) {
  .cart-container {
    flex-direction: column;
    align-items: stretch;
  }

  .cart-containera {
  position: absolute;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  width: 70%;
  }

  .button-container {
    align-items: stretch;
    width: 40%;
  }

  .button-container .cajas  {
    
  width: 15.5vw;
  }

.utility-group-modal {
  position: relative;
  top: 40vh;
 
  transform: translate(-100%);
  width: 100vw;
  max-height: 50vh;
  overflow-y: auto;
  background-color: #fff;
}


}

.cart-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
}

.cart-containera {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
}

.selected-items {
  background-color: #222;
  border: 1px solid #ccc;
  padding: 10px;
}


.selected-items span {
  background-color: #222;

 
}

.selected-items p {
  background-color: #222;
  color: white;
 
}





.selected-items .custom-button {
  background-color: #007bff;
  color: white;
  font-size: 1em;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 2px solid black;
  margin-top: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.selected-items .custom-button:hover {
  background-color: darkblue;
}

.button-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 70%;
}

.buy-button-container {
  margin-top: 10px;
}

.utility-group-modal {
  position: relative;
  top: 25vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  background-color: #222;
}

.utility-group-modal .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.utility-group-modal .item span {
  margin-right: 10px;
}

.utility-nft-image {
  width: 120px; 
  height: 120px; 

}

.utility-group-modal .item button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.utility-group-modal .item button:hover {
  background-color: #0056b3;
}

*/

