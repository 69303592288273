.equine-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  
  /*gap: 1rem;*/
}


.image-container-ballo {
    align-self: flex-start;
    position: inherit;
    display: inline-flex;
    min-width: 50%;
    max-width: 80%;
    min-height: auto;
}




.image-caballo {
  position: relative;
  display: inline-block;
}
.imagenesCaballos {
  position: relative;
  display: inline-flex;
  padding-right: 0;
  margin: 0;
  min-width: 90vw;
  height: auto;
  border-radius: 8px;
}
.caballo-image {
  max-width: 80vw;
  height: auto;
  border-radius: 8px;
}





.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0.5rem;
  /*border-radius: 8px;*/
}

.equine-footer {
  width: 100%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em; /* 1em = 16px por defecto */
  background-color: #2196f3;
}







/* Dispositivos pequeños (móviles en orientación vertical, 320px - 480px) */
@media only screen and (min-width: 280px) and (max-width: 480px) {

 .image-container-ballo img {

    min-width: 60vw;
    max-width: 70vw;

    min-height: auto;
}
}

/* Dispositivos medianos (tabletas en orientación vertical, 481px - 768px) */
@media only screen and (min-width: 481px) and (max-width: 768px) {
.image-container-ballo img{
  
    min-width: 40vw;
    max-width: 40vw;

    min-height: auto;
}
}

/* Dispositivos grandes (tabletas en orientación horizontal y pantallas pequeñas de escritorio, 769px - 1024px) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
 .image-container-ballo img{
   
    min-width: 25vw;
    max-width: 40vw;
    min-height: auto;
}
}

/* Dispositivos extra grandes (pantallas de escritorio grandes, 1025px en adelante) */
@media only screen   and (min-width: 1025px) {
  .image-container-ballo img{
   
    min-width: 20vw;
    max-width: 25vw;
    min-height: auto;
}
}
