
.button {
 display: inline-block;
 width: 50px;
 height: 20px;
 background-color: #fff;
 border-radius: 30px;
 cursor: pointer;
 padding: 0;
}

#toggle {
 display: none;
}

.slider {
 display: block;
 font-size: 10px;
 position: relative;
}

.slider::after {
 content: 'OFF';
 width: 25px;
 height: 25px;
 background-color: #F36F25;
 border: 2px solid #fff;
 border-radius: 50%;
 box-shadow: 0 0 5px rgba(0, 0, 0, .25);
 position: absolute;
 top: -5px;
 left: 0;
 display: grid;
 place-content: center;
 line-height: 0;
 transition: background-color .25s, transform .25s ease-in;
}

#toggle:checked + .slider::after {
 content: 'ON';
 background-color: #12897A;
 transform: translateX(25px) rotate(360deg);
}
















.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 2.5em;
  height: 1.2em;
}


.switch.light {
  font-size: 87px;
  position: relative;
  display: inline-block;
  width: 2.5em;
  height: 1.2em;
}


.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


.slidersun {
  --background: #28096b;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  transition: .5s;
  border-radius: 30px;
}

.slidersundark:before {
  position: absolute;
  content: "";
  height: 1.0em;
  width: 1.0em;
  border-radius: 50%;
  left: 10%;
  bottom: 15%;
  box-shadow: inset 8px -4px 0px 0px #fff000;
  background: var(--background);
  transition: .5s;
}

input:c + .slidersunlight {
  background-color: #522ba7;
}

input .slidersunlight {
  transform: translateX(100%);
 
}

.slidersunlight {
  position: absolute;
  content: "";
  height: 1.0em;
  width: 1.0em;
  border-radius: 50%;
  left: 10%;
  bottom: 15%;
   box-shadow: inset 15px -4px 0px 15px #fff000;
  background: var(--background);
  transition: .5s;
}






















.switche {
  position: relative;
  display: inline-block;
  margin: 0 5px;
}

.switche > span {
  position: absolute;
  top: 14px;
  pointer-events: none;
  font-family: 'Helvetica', Arial, sans-serif;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  text-shadow: 0 1px 0 rgba(0, 0, 0, .06);
  width: 50%;
  text-align: center;
}

input.check-toggle-round-flat:checked ~ .off {
  color: #F36F25;
}

input.check-toggle-round-flat:checked ~ .on {
  color: #fff;
}

.switche > span.on {
  left: 0;
  padding-left: 2px;
  color: #F36F25;
}

.switche > span.off {
  right: 0;
  padding-right: 4px;
  color: #fff;
}

.check-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.check-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input.check-toggle-round-flat + label {
  padding: 2px;
  width: 97px;
  height: 35px;
  background-color: #12897A;
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  -ms-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.check-toggle-round-flat + label:before, input.check-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}

input.check-toggle-round-flat + label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #12897A;

  border-radius: 60px;
}
input.check-toggle-round-flat + label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 48px;
  background-color: #fff;
  -webkit-border-radius: 52px;
  -moz-border-radius: 52px;
  -ms-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
  -webkit-transition: margin 0.2s;
  -moz-transition: margin 0.2s;
  -o-transition: margin 0.2s;
  transition: margin 0.2s;
}

input.check-toggle-round-flat:checked + label {
}

input.check-toggle-round-flat:checked + label:after {
  margin-left: 44px;
}