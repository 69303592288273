/* Estilos para la tabla */
.race-table-position {
  width: 30%;
  border-collapse: collapse;
  margin-left: 5.5em;
}

/* Estilos para las celdas de la tabla */
.race-table-position th, .race-table-position td {
  border: 1px solid #167CBB;
  padding: 0px;
  text-align: center;
  font-size: 0.7em;
  color: white;
}

/* Estilos para las celdas del encabezado */
.race-table-position th {
  background-color: #167CBB;
}

/* Estilos para las filas impares */
.race-table-position tr:nth-child(odd) {
  background-color: #167CBB;
}

/* Estilos para las filas pares */
.race-table-position tr:nth-child(even) {
  background-color: #167CBB;
}


