/* Estilos para la pista de carreras */
.race-track {
  text-align: center;
}

/* Estilos para la tabla de carreras */
.race-table {
  width: 100%; /* Ancho de la tabla */
  margin: 0 auto;
  table-layout: fixed;
  border-collapse: collapse;
}

/* Estilos para las celdas de la tabla */
.race-table td {
  border: 1px solid #ccc;
  padding: 0.7em;
  background: linear-gradient(to top, #12897A 0%, #74af49 20%, #12897A 20%, #dcc99b 60%, #87ceeb 70%, #167CBB 90%, black 100%); /* Gradiente horizontal que simula una pista de carreras con pasto verde, arena y cielo azul */
}

/* Estilos para los nombres de los caballos */
.horse-name {
  width: 8%;
}

/* Estilos para la línea de carrera */
.race-lane {
  position: relative;
  width: 80vw;
  height: 7em; /* Altura total de la celda */
  overflow: hidden;
}

/* Estilos para los puntos de los caballos */
.horse-dot {
  position: absolute;
  bottom: 0;
  width: 4em;
  height: 4em;
  border-radius: 10%;
  z-index: 1;
}

/* Estilos para el ganador */
.winner {
  background-color: #28a745;
}


.race-lane-arbol-tronco {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.race-lane-arbol-tronco::after {
  z-index: 1;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 500%; /* Doble del ancho del contenedor para crear un desplazamiento continuo */
  height: 60%;
background: repeating-radial-gradient(
  circle,
  rgba(255, 0, 0, 0.7),
  rgba(255, 255, 255, 0.5) 1em, /* Cambia la distancia entre los "árboles" */
  transparent 1em, /* Espacio entre los "árboles" */
  transparent 17em /* Ajusta el espacio entre los "árboles" */
);

  animation: none; /* Animación de desplazamiento */
}

.race-lane-arbol-tronco.moving::after {
  animation: moveBarriers 100s linear infinite; /* Activar animación de desplazamiento cuando la carrera esté en progreso */
}


/* Estilos para las barras de la pista de carreras */
.race-lane-barriers {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.race-lane-barriers::after {
  z-index: 10;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 500%; /* Doble del ancho del contenedor para crear un desplazamiento continuo */
  height: 20px; /* Altura de las barras */
  background: repeating-linear-gradient(
    90deg,
    #167CBB,
    #167CBB 3px,
    #ccc 3px,
    #ccc 6px
  ); /* Patrón de barras */
  animation:none; /* Animación de desplazamiento */
}

.race-lane-barriers.moving::after {
  animation: moveBarriers 100s linear infinite; /* Activar animación de desplazamiento cuando la carrera esté en progreso */
}

/* Animación de desplazamiento para las barras */
@keyframes moveBarriers {
  0% {
    transform: translateX(0); /* Desplazamiento inicial */
  }
  100% {
    transform: translateX(-50%); /* Desplazamiento completo */
  }
}








/* Media query para pantallas pequeñas (por ejemplo, móviles) */
@media (max-width: 768px) {
  .race-lane {
    width: 80vw; /* Ajustar a todo el ancho de la pantalla en dispositivos móviles */
  }
}