.horse {
  width: 200px;
  height: 150px;
  background-color: brown;
  position: relative;
  overflow: hidden;
}

.head {
  width: 70px;
  height: 70px;
  background-color: brown;
  position: absolute;
  top: 0;
  left: 70px;
  border-radius: 50%;
}

.body {
  width: 140px;
  height: 80px;
  background-color: brown;
  position: absolute;
  top: 70px;
  left: 30px;
  border-radius: 50%;
}

.Nav-Market{
padding: 0.2em;
margin: 1em;
}

.leg {
  width: 30px;
  height: 100px;
  background-color: brown;
  position: absolute;
  bottom: 0;
  border-radius: 10px;
}

.leg:nth-child(odd) {
  left: 20px;
}

.leg:nth-child(even) {
  right: 20px;
}

.center-titulo {
  text-align: center;
}

.center-titulo h1 {
  margin: 0;
  padding: 0;
}


.container-market-alimentos {
  z-index: 10;
  /*background: linear-gradient(135deg, rgba(22, 124, 187, 0.3), rgba(213, 75, 75, 0.3), rgba(66, 63, 63, 0.3));*/
  background-image: url(https://www.blockhorseracing.com/image/fondos/manzanas.jpg);
  background-size: cover;  /* Añade esta propiedad para que la imagen se adapte y cubra el fondo */
  background-position: center;  /* Centra la imagen en el fondo */
  margin-left: 0vh;
  margin-right: 0vh;
}


.container-market-implementos {
  z-index: 10;
  /*background: linear-gradient(135deg, rgba(22, 124, 187, 0.3), rgba(213, 75, 75, 0.3), rgba(66, 63, 63, 0.3));*/
  background-image: url(https://www.blockhorseracing.com/image/fondos/implementos8.jpg);
  background-size: cover;  /* Añade esta propiedad para que la imagen se adapte y cubra el fondo */
  background-position: center;  /* Centra la imagen en el fondo */
  margin-left: 0vh;
  margin-right: 0vh;
}