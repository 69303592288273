@media only screen and (max-width: 600px) {
  #tablaInscripcion table {
    background-color: white;
    /* Estilos para la tabla en dispositivos móviles */
    width: 100%; /* Ajustar el ancho al 100% */
    /* Agregar otros estilos según sea necesario */
  }
}



.tableInscripcion  {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se ajusten a nuevas líneas */
  justify-content: space-between; 
  gap: 2px; /* Espaciado entre los elementos */
}

.inscritos {
  display: flex;
  flex-wrap: wrap;
}

.inscritos tbody {
  display: flex;
  flex-wrap: wrap;
}

.inscritos tr {
  flex: 0 0 20%; /* Cada fila ocupa el 50% del ancho del contenedor */
  padding: 5px;
  /*box-sizing: border-box;*/
}

.inscritos td {
  background-color: black;
  width: 80%;
}


/* Estilo CSS */
.name_race {
  max-width: 80%;
  word-wrap: break-word; /* Permite que el texto se envuelva automáticamente */
  /* Otros estilos que desees aplicar al contenedor */
}

.rowInscripcion{
  background-color: #222;

  padding: 0.4em;
  border-radius: 8px;
  flex: 1 1 calc(25% - 16px); /* 4 columnas ajustables */
  text-align: center;
  box-sizing: border-box; /* Para que el padding se incluya dentro del ancho */
}