.ganadores-section {
  padding: 1em;
}

.ganadores-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-around;
}

.ganador-column {
  flex: 1 1 20%;
  border: 1px solid #ddd;
  padding: 1em;
  border-radius: 4px;
  text-align: center;
}

.ganador-column p {
  margin: 0.5em 0;
}
