/* Mobile First Styles */
#races_runs-ultimasSeisTabla {
  margin-top: 20px;
  background-color: #1c1c1c; /* Color de fondo oscuro para armonizar con el diseño */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  overflow-x: auto; /* Permitir desplazamiento horizontal en pantallas pequeñas */
}

#races_runs-ultimasSeisTabla table {
  width: 100%;
  border-collapse: collapse;
}

#races_runs-ultimasSeisTabla th, 
#races_runs-ultimasSeisTabla td {
  padding: 10px;
  border: 1px solid #444; /* Bordes más oscuros */
  text-align: center;
  color: #fff; /* Texto blanco para mejor contraste */
  word-wrap: break-word; /* Ajustar texto dentro de las celdas */
}

#races_runs-ultimasSeisTabla th {
  background-color: #4caf50; /* Color verde para los encabezados */
  color: #fff; /* Texto blanco */
}

#races_runs-ultimasSeisTabla td a {
  color: #1e88e5; /* Enlaces en azul */
  text-decoration: none;
}

#races_runs-ultimasSeisTabla td a:hover {
  text-decoration: underline;
}

#races_runs-ultimasSeisTabla h2 {
  margin: 0;
  font-size: 1em;
  color: #ffeb3b; /* Texto amarillo para premios */
}

.races_runs-pantallantigua {
  position: inherit;
  max-width: 100%;
  margin-top: 20px;
  background-color: #1c1c1c; /* Color de fondo oscuro para armonizar con el diseño */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  overflow-x: auto; /* Permitir desplazamiento horizontal en pantallas pequeñas */
}

.races_runs-pantallantigua table {
  width: 100%;
  border-collapse: collapse;
}

.races_runs-pantallantigua th, 
.races_runs-pantallantigua td {
  border: 1px solid #444; /* Bordes más oscuros */
  padding: 8px;
  text-align: left;
  color: #fff; /* Texto blanco para mejor contraste */
  word-wrap: break-word; /* Ajustar texto dentro de las celdas */
}

.races_runs-pantallantigua th {
  background-color: #4caf50; /* Color verde para los encabezados */
  color: #fff; /* Texto blanco */
}

.races_runs-pantallantigua a {
  color: #1e88e5; /* Enlaces en azul */
  text-decoration: none;
}

.races_runs-pantallantigua a:hover {
  text-decoration: underline;
}

/* Media Queries para mejorar la responsividad */
@media (max-width: 768px) {
  #races_runs-ultimasSeisTabla th, 
  #races_runs-ultimasSeisTabla td,
  .races_runs-pantallantigua th, 
  .races_runs-pantallantigua td {
    padding: 5px;
    font-size: 14px; /* Ajustar tamaño de fuente para pantallas medianas */
  }
  #races_runs-ultimasSeisTabla h2 {
  font-size: 0.5em;
}

}

@media (max-width: 480px) {
  #races_runs-ultimasSeisTabla th, 
  #races_runs-ultimasSeisTabla td,
  .races_runs-pantallantigua th, 
  .races_runs-pantallantigua td {
    padding: 3px;
    font-size: 12px; /* Ajustar tamaño de fuente para pantallas pequeñas */
  }
#races_runs-ultimasSeisTabla h2 {
  font-size: 0.75em;
}



}
