@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}


.container-inventario-Alimentos {
  background: linear-gradient(135deg, rgba(22, 124, 187, 0.3), rgba(213, 75, 75, 0.3), rgba(66, 63, 63, 0.3));
  /*background-image: url(https://www.blockhorseracing.com/image/backgroundballos/track2.jpg);*/
  background-size: cover;  /* Añade esta propiedad para que la imagen se adapte y cubra el fondo */
  background-position: center;  /* Centra la imagen en el fondo */
  margin-left: 10vh;
  margin-right: 10vh;
}

.wrapp {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 100%));
  gap: 2rem;
  justify-content: center;
}


.claim-button-wrapper {
  margin-top: 10px; /* Espacio entre walletData y el botón */
}








.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 10vh;
 
}



 .wrapp .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0px 0;
}

.container .card {
  position: relative;
  min-width: 260px;
  height: 360px;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.2),
  inset -4px -4px 15px rgba(255, 255, 255, 0.1),
  4px 4px 15px rgba(0, 0, 0, 0.3), -4px -4px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 8px;
  transition: 0.5s;
}





 .container .card .box-nft {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: block;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}


 .container .card .box-nft:hover {
  transform: translateY(-50px);
}

 .container .card .box-nft:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

 .container .card .box-nft .content  {
  padding: 35px;
  text-align: center;
}





 .cajas {
  position: relative;
  border-radius: 8px;
  border: 3px solid #12897A;
  width: 100%;
  background-size: cover;
}



.cajas img {
  width: 100%;
 border: 2px solid transparent;
 top: 2px;
 
 }

 .identidad {
  position: absolute;
  top: 0;
  right: 0;
  background-color: black;
  color: white;
  border-radius: 40%;
  
}

.nombre {
  position: absolute;
  bottom: -2%;
  transform: translateY(-50%);
  color: white;
  padding: 1%;

  
  
}


 .cajas svg{
width: 0.2rem;
height: 0.2rem;
fill:white;
}

.icono {
position: absolute;
background: white;
border-spacing: 3px;
page-break-after: 5px;
margin: 5px;
width: 55%;
height: 55%;
 border: 3px solid #ffffff;
}
/**/


  .cofres-disponibles {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    min-width: 300px;
    margin: 1rem auto;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 10px #ffffff;
    transition: box-shadow 0.3s ease-in-out;
  }

  .btn-1 {
    order: 1;
  }

  .btn-2 {
    order: 2;
  }

  .btn-3 {
    order: 3;
  }

  .cofres-disponibles button {
    position: relative;

  }

  .cofres-disponibles button:hover::before {
    z-index: 12;
    content: attr(data-text); /* obtiene el valor de data-text */
    position: absolute;
    bottom: 1px; /* ajusta la posición del texto */
    left: 10%; /* centra el texto horizontalmente */
    transform: translateX(-25%); /* centra el texto horizontalmente */
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
  }

  .cofres-disponibles p {
    font-size: 0.8rem;
    font-weight: bold;
    color: #ffffff;
  }


button[type="submit"] img {
 position: relative;
  width: 2em;
  height: 2em;
}


button[type="submit"] {
  padding: 0em;
  background-color: transparent;
  border: none;
  cursor: pointer; /* Agregar esto para cambiar el cursor a una mano */
  transition: background-color 0.3s ease; /* Agregar una transición para suavizar el cambio de color */
  margin: 0em;
}

button[type="submit"]:hover {
  background-color: #0099ff; /* Cambiar el color de fondo al pasar el puntero */
  border-radius: 20%;
}


button[type="button"] {
  position: relative;
  padding: 0em;
  background-color: transparent;
  border: none;
  cursor: pointer; /* Agregar esto para cambiar el cursor a una mano */
  transition: background-color 0.3s ease; /* Agregar una transición para suavizar el cambio de color */
 margin: 0px;
}

button[type="button"] img {
  width: 15px;
  height: 15px;
}

button[type="button"]:hover {
  background-color: #0099ff; /* Cambiar el color de fondo al pasar el puntero */
  border-radius: 20%;
}




.iconosvg {
position: absolute;
background: white;
margin: 5px;
width: 7.5%;
height: 6.5%;
border-radius: 45%;
}



.brightness {filter: brightness(250%);}
.contrast {filter: contrast(180%);}
.grayscale {filter: grayscale(100%);}
.huerotate {filter: hue-rotate(180deg);}
.invert {filter: invert(100%);}
.opacity {filter: opacity(50%);}
.saturate {filter: saturate(7);}
.sepia {filter: sepia(100%);}
.shadow {filter: drop-shadow(8px 8px 10px green);}


 .container .card .box-nft .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

 .container .card .box-nft .content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

 .container .card .box-nft .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}




 .container .card .box-nft .content input{
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  /*background: black;*/
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}


 .container .card .box-nft .content input:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}


.container .card .box-nft .content  .inpu .input-price{
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 2px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

 .input-container {
  position: relative;
  display: flex;
  justify-content: space-between;

  
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 1px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}




 .container .card .box-nft .content .inpu input:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}

/* Media Query para pantallas de 750px o menos */
@media (max-width: 750px) {
  .cofres-disponibles {
    flex-direction: flex;
    align-items: center;
    min-width: 220px;
  
  }

}