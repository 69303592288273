/* src/components/TokenSelector.css */
.token-selector {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.token-selector label {
  margin-right: 10px;
}

.token-selector select {
  padding: 5px;
  margin-right: 10px;
}

.buy-token-button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 4px;
}

.buy-token-button:hover {
  background-color: #45a049;
}
