.races_runs-GanadasTotal {
    padding: 0.2em;
    background-color: #497ba6;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: inherit;
    max-width: 100%;
}

.races_runs-Table {
    width: 100%;
    border-collapse: collapse;
}

.races_runs-Heading {
    background-color: #222;
    color: white;
}

.races_runs-Heading th {
    padding: 10px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.races_runs-Cell-info {
    padding: 10px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.races_runs-Cell-info .svg {
    display: block;
    margin: 0 auto;
}

.races_runs-Cell-info span {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #1e88e5;
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    z-index: 10;
}

.races_runs-Cell-info:hover span {
    display: inline;
}

.races_runs-Row {
    background-color: #fff;
}

.races_runs-Row td {
    background-color: #222 ;
    padding: 10px;
    text-align: center;
    border-top: 1px solid #ddd;
}


.table-info-b-resultados{
    padding: 1em;

}