.horse-owner {
  text-align: center;
  font-size: 1.2em;
  color: #FBBF24;
  margin-bottom: -0.2em; /* Coloca el texto del propietario justo encima de la tarjeta */
}

.horse-card {
  position: relative;
  border: 1px solid #1E3A8A;
  border-radius: 10px;
  background-color: #101820;
  width: 250px;
  transition: transform 0.3s ease;
  margin: 0.5em 0.5em 0   em;
}
.horse-card  p{
  color: #f3f3f3;
}

.horse-card .rarity {
 position: relative;
  top: -5em;
  color: #FBBF24;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5em;

}

.image-container {
  position: relative;
  top: -5em; /* Ajusta el valor para controlar cuánto sobresale la imagen */
  left: 20%;
  display: flex;
  justify-content: center;
  margin-bottom: -1em;
}


image-container {
  display: flex;
  justify-content: flex-endS
}

.horse-card img.horse-image {
  width: 90%;
  height: auto;
  /*object-fit: cover;*/
  border-radius: 8px 8px 0 0;
}

.status { 
  position: relative;
  display: flex;
  top: -3em;

  color: #FBBF24;
  font-size: 1em;
  text-align: center;
  margin: 0; /* Elimina el margen superior e inferior */
  padding-top: 0; /* Evita cualquier espacio adicional arriba */
  z-index: 500;}

.status.ready {
  color: #10B981;
  z-index: 500;
}

.progress-bar {
  margin: 1% 3% 1% 3%;
  height: 10px;
  background-color: #FBBF24;
  border-radius: 5px;
z-index: 999;}

.stats{
  position: relative;
  z-index: 500;
}

.awards {
  display: flex;
  flex-direction: column;
z-index: 500;}

.award {
  display: flex;
  justify-content: space-between;
  background: rgba(255, 215, 0, 0.2);
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
}

.btn-train, .btn-history {
  background-color: #1E3A8A;
  color: #FFF;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-train:hover, .btn-history:hover {
  background-color: #10B981;
}

.horse-card {
  position: relative;
  border: 1px solid #1E3A8A;
  border-radius: 10px;
  background-color: #101820;
  color: #f3f3f3;
  width: 250px;
  padding-top: 5em; /* Da espacio para la imagen que está en posición absoluta */
  transition: transform 0.3s ease;
  margin: 0.5em 0.5em 0;
}

/*.horse-card .rarity {
  color: #FBBF24;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0.5em;
}

.image-container {
  position: relative;
  height: 0;
  margin-top: -3em; /* Controla la superposición 
}*/

.horse-image {
  position: absolute;
  top: -5em; /* Ajusta cuánto sobresale la imagen */
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  height: auto;
  border-radius: 8px 8px 0 0;
}

.status {
  color: #FBBF24;
  font-size: 1em;
  text-align: center;
  margin-top: -1em; /* Reduce cualquier espacio adicional entre la imagen y el `Nft ID` */
}

.stats {
  color: #FBBF24;
  font-size: 1em;
  text-align: center;
  margin-top: -1em; /* Reduce cualquier espacio adicional entre la imagen y el `Nft ID` */
  background-color: #1E3A8A;
  background-color: rgba(30, 58, 138, 0.8); /* 80% opacidad */
  border-radius: 10px; /* Bordes redondeados */
  padding: 1em; /* Espacio interno */
border-radius: solid;
width: 90%;
margin-left: auto;
margin-right: auto;
}

