@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

/* General layout */
.tableroPresale {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
  gap: 0.5em
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100vh;
  background: #f5f5f5;
}

/* Card styles */
.container .card {
  position: relative;
  width: 300px;
  height: 400px;
  margin: 1rem;
  background: linear-gradient(145deg, #333, #222);
  box-shadow: 
    5px 5px 15px rgba(0, 0, 0, 0.2),
    -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.container .card:hover {
  transform: translateY(-10px);
  box-shadow: 
    10px 10px 30px rgba(0, 0, 0, 0.4),
    -10px -10px 30px rgba(255, 255, 255, 0.2);
}

/* Box inside card */
.container .card .box {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  background: #2a2b2f;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  transition: background 0.3s ease;
}

.container .card .box:hover {
  background: #1e1f22;
}

/* Card content */
.container .card .box img {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.container .card .box h5 {
  font-size: 1.2em;
  color: #ffd700;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 10px;
}

.container .card .box h4,
.container .card .box h5 {
  color: #00bcd4;
  font-size: 1em;
  text-shadow: 0.5px 0.5px 2px rgba(0, 0, 0, 0.5);
}

/* Button styles */
.container .card .box input[type="submit"] {
  padding: 10px 25px;
  font-size: 1em;
  font-weight: 600;
  color: white;
  background: #2196f3;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}

.container .card .box input[type="submit"]:hover {
  background: #1976d2;
  transform: scale(1.05);
}

.price-tag {
  position: relative;
  max-width: 70%;
  top: -2em;
  background:#2a2b2f;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.9em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.presale-info {
  position: relative;
  top: -2em;
  background:#2a2b2f;
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.9em;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

submit-presale{
  width: 100%;
}

/* Responsive design */
@media (max-width: 768px) {
  .container .card {
    width: 100%;
    height: auto;
  }

  .container .card .box {
    padding: 15px;
  }
}
