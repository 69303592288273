.img-container-info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.img-container-info img {
  max-width: 8rem;
  height: auto;
  padding: 0.1rem;
}


.image-wrapper {
  position: relative;
}


.image-wrapper img {
  max-width: 4rem;
  height: auto;
  padding: 0.1rem;
}


.image-wrappering  {
  max-width: 50%;
  max-height: 50% ;
  height: auto;
  padding: 1rem;
 background: black;
 border: 0.3vw solid grey;
}

.image-wrappering  p{
  max-width: 2rem;
  max-height: 2rem ;
  height: auto;
}
.image-wrappering img {
  max-width: 8rem;
  max-height: 8rem ;
  height: auto;
  border-radius: 60%;
  padding: 0.1rem;
background: black;
}
