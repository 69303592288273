/* Variables */
:root {
  --background-img: url(https://www.blockhorseracing.com/image/ballos/sombra1.png);
  
  /* Colores de fondo por rareza */
  --common-bg-color: darkgray;
  --uncommon-bg-color: dimgray;
  --rare-bg-color: lightgray;
  --spectral-bg-color: lightgray;
  --epic-bg-color: green;
  --mythic-bg-color: indigo;
  --legendary-bg-color: orangered;
  
  /* Gradientes de rareza */
  --common-grad: repeating-linear-gradient(45deg, yellow, yellow 10px, transparent 10px, transparent 20px);
  --uncommon-grad: repeating-linear-gradient(45deg, blue, blueviolet 10px, transparent 10px, transparent 20px);
  --rare-grad: repeating-linear-gradient(45deg, gold, goldenrod 10px, transparent 10px, transparent 20px);
  --spectral-grad: repeating-linear-gradient(45deg, #FF0000, #00FF00 10px, transparent 10px, transparent 20px);
  --epic-grad: repeating-linear-gradient(45deg, green, greenyellow 10px, transparent 10px, transparent 20px);
  --mythic-grad: repeating-linear-gradient(45deg, #8A2BE2, purple 10px, transparent 10px, transparent 20px);
  --legendary-grad: repeating-linear-gradient(45deg, red, #800000 10px, transparent 10px, transparent 20px);
}

/* Estilos generales para imágenes de caballos */
.caballo-info-imagen-common, 
.caballo-info-imagen-uncommon, 
.caballo-info-imagen-rare, 
.caballo-info-imagen-spectral, 
.caballo-info-imagen-epic, 
.caballo-info-imagen-mythic, 
.caballo-info-imagen-legendary,
.caballo-info-imagen-Xcommon, 
.caballo-info-imagen-Xuncommon, 
.caballo-info-imagen-Xrare, 
.caballo-info-imagen-Xspectral, 
.caballo-info-imagen-Xepic, 
.caballo-info-imagen-Xmythic, 
.caballo-info-imagen-Xlegendary {
  width: 135%;
  max-height: 12em;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: var(--background-img);
  background-size: cover;
  background-position: center;
}

/* Estilos específicos por rareza */
.caballo-info-imagen-common {
  background-color: var(--common-bg-color);
  background-image: var(--background-img), var(--common-grad);
}

.caballo-info-imagen-uncommon {
  background-color: var(--uncommon-bg-color);
  background-image: var(--background-img), var(--uncommon-grad);
}

.caballo-info-imagen-rare {
  background-color: var(--rare-bg-color);
  background-image: var(--background-img), var(--rare-grad);
}

.caballo-info-imagen-spectral {
  background-color: var(--spectral-bg-color);
  background-image: var(--background-img), var(--spectral-grad);
}

.caballo-info-imagen-epic {
  background-color: var(--epic-bg-color);
  background-image: var(--background-img), var(--epic-grad);
}

.caballo-info-imagen-mythic {
  background-color: var(--mythic-bg-color);
  background-image: var(--background-img), var(--mythic-grad);
}

.caballo-info-imagen-legendary {
  background-color: var(--legendary-bg-color);
  background-image: var(--background-img), var(--legendary-grad);
}





/* Estilos predeterminados para pantallas pequeñas (móviles primero) */

/* Tablets pequeñas y móviles grandes (mínimo 576px) */
@media screen and (min-width: 406px) {
  /* Estilos para pantallas más grandes que 576px */
/*    .content-race-img {
    max-width: 135px;
    max-height: 135px;
  }
*/
 .caballo-info-imagen-common, 
.caballo-info-imagen-uncommon, 
.caballo-info-imagen-rare, 
.caballo-info-imagen-spectral, 
.caballo-info-imagen-epic, 
.caballo-info-imagen-mythic, 
.caballo-info-imagen-legendary,
.caballo-info-imagen-Xcommon, 
.caballo-info-imagen-Xuncommon, 
.caballo-info-imagen-Xrare, 
.caballo-info-imagen-Xspectral, 
.caballo-info-imagen-Xepic, 
.caballo-info-imagen-Xmythic, 
.caballo-info-imagen-Xlegendary {
    width: 110%;
  }

}



/* Tablets pequeñas y móviles grandes (mínimo 576px) */
@media screen and (min-width: 576px) {
  /* Estilos para pantallas más grandes que 576px */
    .content-race-img {
    max-width: 135px;
    max-height: 135px;
  }

 .caballo-info-imagen-common, 
.caballo-info-imagen-uncommon, 
.caballo-info-imagen-rare, 
.caballo-info-imagen-spectral, 
.caballo-info-imagen-epic, 
.caballo-info-imagen-mythic, 
.caballo-info-imagen-legendary,
.caballo-info-imagen-Xcommon, 
.caballo-info-imagen-Xuncommon, 
.caballo-info-imagen-Xrare, 
.caballo-info-imagen-Xspectral, 
.caballo-info-imagen-Xepic, 
.caballo-info-imagen-Xmythic, 
.caballo-info-imagen-Xlegendary{
    width: 105%;
  }

}

/* Tablets y pantallas medianas (mínimo 768px) */
@media screen and (min-width: 768px) {
  /* Estilos para pantallas más grandes que 768px */
   .caballo-info-imagen-Common, 
.caballo-info-imagen-uncommon, 
.caballo-info-imagen-rare, 
.caballo-info-imagen-spectral, 
.caballo-info-imagen-epic, 
.caballo-info-imagen-mythic, 
.caballo-info-imagen-legendary,
.caballo-info-imagen-Xcommon, 
.caballo-info-imagen-Xuncommon, 
.caballo-info-imagen-Xrare, 
.caballo-info-imagen-Xspectral, 
.caballo-info-imagen-Xepic, 
.caballo-info-imagen-Xmythic, 
.caballo-info-imagen-Xlegendary {
    width: 100%;
  }
}

/* Laptops pequeñas (mínimo 992px) */
@media screen and (min-width: 992px) {
  /* Estilos para pantallas más grandes que 992px */
   .caballo-info-imagen-common, 
.caballo-info-imagen-uncommon, 
.caballo-info-imagen-rare, 
.caballo-info-imagen-spectral, 
.caballo-info-imagen-epic, 
.caballo-info-imagen-mythic, 
.caballo-info-imagen-legendary,
.caballo-info-imagen-Xcommon, 
.caballo-info-imagen-Xuncommon, 
.caballo-info-imagen-Xrare, 
.caballo-info-imagen-Xspectral, 
.caballo-info-imagen-Xepic, 
.caballo-info-imagen-Xmythic, 
.caballo-info-imagen-Xlegendary {
    width: 100%;
  }
}

/* Laptops grandes y pantallas de escritorio (mínimo 1200px) */
@media screen and (min-width: 1200px) {
  /* Estilos para pantallas más grandes que 1200px */
    .caballo-info-imagen-common, 
.caballo-info-imagen-uncommon, 
.caballo-info-imagen-rare, 
.caballo-info-imagen-spectral, 
.caballo-info-imagen-epic, 
.caballo-info-imagen-mythic, 
.caballo-info-imagen-legendary,
.caballo-info-imagen-Xcommon, 
.caballo-info-imagen-Xuncommon, 
.caballo-info-imagen-Xrare, 
.caballo-info-imagen-Xspectral, 
.caballo-info-imagen-Xepic, 
.caballo-info-imagen-Xmythic, 
.caballo-info-imagen-Xlegendary {
    width: 100%;
  }
}

/* Pantallas muy grandes (mínimo 1600px) */
@media screen and (min-width: 1600px) {
  /* Estilos para pantallas más grandes que 1600px */
   .caballo-info-imagen-common, 
.caballo-info-imagen-uncommon, 
.caballo-info-imagen-rare, 
.caballo-info-imagen-spectral, 
.caballo-info-imagen-epic, 
.caballo-info-imagen-mythic, 
.caballo-info-imagen-legendary,
.caballo-info-imagen-Xcommon, 
.caballo-info-imagen-Xuncommon, 
.caballo-info-imagen-Xrare, 
.caballo-info-imagen-Xspectral, 
.caballo-info-imagen-Xepic, 
.caballo-info-imagen-Xmythic, 
.caballo-info-imagen-Xlegendary {
    width: 100%;
  }
}
