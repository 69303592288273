/* RaceOpenBet.css */
.race-open-bet-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.race-type-selector {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.race-type-title {
  margin: 0;
  margin-right: 10px;
  font-size: 1.2em;
  color: #fff; /* Color blanco */
}

.race-type-toggle-button { /* Clase CSS única para el botón */
  background: none;
  border: 2px solid #fff; /* Borde blanco */
  color: #fff; /* Color blanco */
  font-size: 1em;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.race-type-toggle-button:hover { /* Estilo al pasar el ratón */
  background-color: rgba(255, 255, 255, 0.2); /* Fondo ligeramente translúcido al pasar el ratón */
}

.race-item {
  margin-bottom: 5px;
}

.race-item h2 a {
  color: #fff; /* Color blanco */
  text-decoration: none;
  display: flex;
  align-items: center;
}

.race-id {
  margin-right: 5px;
}

.race-pot {
  display: flex;
  align-items: center;
}

.icon {
  width: 1em;
  height: 1em;
  margin-left: 2px;
}
