.card-info {
  position: relative;
  width: 100%;
  height: 100%;
  background: #2e2e2e;
  display: flex;

}



.card-info .content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #2e2e2e;
  overflow: hidden;
  z-index: 10;
}


.card-info .content-img {
  display: flex;
  position: relative;
  max-width: 30vw;
  max-height: 30vh;
  border-radius: 30%;
  top: 10px ;
  overflow: hidden;
  z-index: 10;
}



.card-info .content-img h4{
    fontFamily: 'cursive';
    z-index:998; 
    fontSize: '1.2em';
    fontWeight: 'bold'; 
    textTransform: 'uppercase';
    color:'#854D0D';
    textAlign: 'right';
    width: '60%';
    margin: 0;
    color: 'gold';
textShadow: '2px 2px 0 #a98e27, 4px 4px 0 #854D0D';
 
}


.card-info .content p.Status{
z-index:19;
position: relative;
left: 4em;
top:  2em;
text-shadow: 
      1px -1px 0 #767676, 
      -1px 2px 1px #737272, 
      -2px 4px 1px #767474, 
      -3px 6px 1px #787777, 
      -4px 8px 1px #7b7a7a, 
      -5px 10px 1px #7f7d7d;
font-style :italic;
font-weight:1px;
text-transform: initial;
color: gold;
}








/*.card-info .name{
z-index:15;
position: relative;
top: -10px;
text-align: left;
object-fit: cover;
}

.card-info .mint{
z-index:15;
position: absolute;
top: 0px;
left: 40%;
text-align: left;
object-fit: cover;
}



.card-info .velocidad{
z-index:15;
position: absolute;
top: 44px;
left: 40%;
text-align:left;
object-fit: cover;
display: flex;
}

.card-info .velocidad .svg{
width: 1.5rem;
height: 1.5rem;
fill:white;
}*/





/*
.card-info .resistencia{
z-index:15;
position: absolute;
top: 68px;
left: 40%;
text-align:left;
object-fit: cover;
display: flex;
}

.card-info .resistencia .svg{
width: 1.4 rem;
height: 1.4rem;
fill:white;
}

.card-info .resistencia span {
display: none;
}

.card-info .resistencia:hover span {
  color: yellow;
 background: rgba(76, 175, 80, 0.1);
  display: block;
}*/

/*

.card-info .agilidad{
z-index:15;
position: absolute;
top: 88px;
left: 38%;
text-align:left;
object-fit: cover;
display: flex;
}

.card-info .agilidad .svg{
width: 1.5rem;
height: 1.5rem;
fill:white;
}

.card-info .agilidad span {
display: none;
}

.card-info .agilidad:hover span {
  color: yellow;
 background: rgba(76, 175, 80, 0.1);
  display: block;
}

*/
.card-info .sexo{
z-index:15;
position: absolute;
top: 0px;
left: 1px;
display: flex;
}


.card-info .content .icon {
  color: var(--color);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  background: #2e2e2e;
  box-shadow: 0 0 0 4px #2e2e2e,
    0 0 0 6px var(--color);
  transition: 0.5s ease-in-out;
}

.card-info:hover .content .icon {
  background: var(--color);
  color: #2e2e2e;
  box-shadow: 0 0 0 4px #2e2e2e,
    0 0 0 300px var(--color);
}

.card-info .content .text h3 {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 500;
  transition: 0.5s ease-in-out;
}

.card-info:hover .content .text h3 {
  color: #2e2e2e;
}

.card-info .content .text p {
  color: #999;
  transition: 0.5s ease-in-out;
}



.card-info .content .text a {
  position: relative;
  display: inline-block;
  background: var(--color);
  color: #2e2e2e;
  text-decoration: none;
  font-weight: 500;
  margin-block-start: 10px;
  padding: 8px 15px;
}

.card-info:hover .content .text a {
  background: #2e2e2e;
  color: var(--color);
}






.card-info-c {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffff;
  display: flex;

}

.card-info-c .content {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1px 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background:#55686f;
  overflow: hidden;
  z-index: 10;
}


.card-info-c .content.GanadasTotal{
z-index:15;
position: absolute;
text-align:left;
border-color: black;
background: blue;
}

.card-info-c .content .GanadasTotal .svg{
width: 2rem;
height: 2rem;
fill:white;
}





        .Table

        {

            display: table;
            padding-top: 5px;
            padding-bottom: 5px;

        }

        

     

        .Heading

        {

            display: table-row;

            font-weight: bold;

            text-align: center;

        }

        .Row

        {

            display: table-row;

        }

        .Cell-info

        {

            display: table-cell;

            border: solid;

            border-width: thin;

            padding-left: 5px;

            padding-right: 5px;


        }


.Cell-info .svgb{
width: 1.5rem;
height: 1.5rem;
fill: black;
}


.boton-entrenar{
  position: relative;
  bottom: 2vh;
}
  
.boton-entrenar-modulo{
 display: grid;
  
  bottom: 2vh;
}  

        .Cell-info span {
padding: 10px;
display: none;
}

.Cell-info:hover span {
}







/* Dispositivos pequeños (móviles en orientación vertical, 320px - 480px) */
@media only screen and (max-width: 480px) {
}

/* Dispositivos medianos (tabletas en orientación vertical, 481px - 768px) */
@media only screen and (max-width: 768px) {
}

/* Dispositivos grandes (tabletas en orientación horizontal y pantallas pequeñas de escritorio, 769px - 1024px) */
@media only screen and (max-width: 1024px) {

}

/* Dispositivos extra grandes (pantallas de escritorio grandes, 1025px en adelante) */
@media only screen and (min-width: 1025px) {

}
