@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.wrapp {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 100%));
  gap: 2rem;
  justify-content: center;
}

.wrappBallos {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 100%));
  gap: 2rem;
  justify-content: center;
}


 .wrappBallos .container-dash {
  margin: 1px 0;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  scrollbar-width: auto; /* Ancho del scroll en navegadores que no admiten WebKit */
  scrollbar-color: rgba(33, 150, 243, 1) rgba(0, 0, 255, 0.5); /* Colores del scroll en navegadores que admiten WebKit */



}


.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 10vh;
 
}



 .wrapp .container-dash {
  margin: 1px 0;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  scrollbar-width: auto; /* Ancho del scroll en navegadores que no admiten WebKit */
  scrollbar-color: rgba(33, 150, 243, 1) rgba(0, 0, 255, 0.5); /* Colores del scroll en navegadores que admiten WebKit */



}

.imagenesCaballos {
    position: relative;
    display: inline-flex;
    padding-right: 0;
    margin: 0;
    max-width: 50vw;
    height: auto;
    border-radius: 8px;
}

.container-dash .card {
  position: relative;
  min-width: 260px;
  height: 360px;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.2),
  inset -4px -4px 15px rgba(255, 255, 255, 0.1),
  4px 4px 15px rgba(0, 0, 0, 0.3), -4px -4px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 8px;
  transition: 0.5s;
}




.container-dash .card:nth-child(1) .box .content input {
  background: #2196f3;
}

 .container-dash .card:nth-child(2) .box .content input {
  background: #e91e63;
}

 .container-dash .card:nth-child(3) .box .content input {
  background: #23c186;
}
 .container-dash .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}


 .container-dash .card .box:hover {
  /*transform: translateY(-50px);*/
  /*background: #2196f3;*/
  background: rgba(33, 150, 243, 0.5); /* Un tono de azul con 50% de opacidad */

box-shadow: inset 4px 4px 4px rgba(33, 150, 243, 0.2);

  
}

 .container-dash .card .box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

 .container-dash .card .box .content  {
  padding: 20px;
  text-align: center;
}




 .container-dash .card .box .content img {
  position: relative;
  padding: 0px;
  border-radius: 8px;
  color: rgba(255, 255, 255, 0.1);
}




 .caja {
  position: relative;
  border-radius: 8px;
  border: 3px solid #12897A;
  width: 100%;
  background-size: cover;
}



.caja img {
  width: 100%;
 border: 2px solid transparent;
 top: 15px;
 
 }

 .cajo {
  position: relative;
  border-radius: 8px;
  border: 3px solid #12897A;
  width: 100%;
  background-size: cover;
}



.cajo img {
  width: 100%;
  border-radius: 10px;
 border: 0.5em solid transparent;
 
 
 }

 .card .box .content .arrow {
  display: inline-block;
  opacity: 0;
  transition: opacity 0.3s;
}

.card .box .content:hover .arrow {
  opacity: 1;
}

.card .box .content .arrow {
  animation: fade 2s infinite;
}

@keyframes fade {
  0%, 25% {
    opacity: 1;
  }
  33.33%, 58.33% {
    opacity: 0;
  }
  66.66%, 91.66% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


.icono-caballos {
position: absolute;
background: white;
border-spacing: 3px;
page-break-after: 5px;
margin: 5%;
width: 15%;
height: 15%;
 border: 3px solid #ffffff;
 cursor: pointer;
}


.brightness {filter: brightness(250%);}
.contrast {filter: contrast(180%);}
.grayscale {filter: grayscale(100%);}
.huerotate {filter: hue-rotate(180deg);}
.invert {filter: invert(100%);}
.opacity {filter: opacity(50%);}
.saturate {filter: saturate(7);}
.sepia {filter: sepia(100%);}
.shadow {filter: drop-shadow(8px 8px 10px green);}


 .container-dash .card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

 .container-dash .card .box .content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

 .container-dash .card .box .content p {
  font-size: 0.9rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}




 .container-dash .card .box .content input{
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}


 .container-dash .card .box .content input:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}


.modal-close-info{
  background: red;
  z-index: 50;
   align-self: flex-end;
    font-size: 20px;
    cursor: pointer;
    color: #222;
      position: absolute;
    top: 1rem;
    right: 1rem;
}




 