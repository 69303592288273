.equino-stats {
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  color: #e0e4e8;
  background: linear-gradient(135deg, #0a1f4a, #111d3a);
  border: 1px solid #2b4b89;
  padding: 0.4em;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
  text-align: left;
  width: 100%;
  max-width: 350px;
}

.stat-item {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.8em;
  margin: 0.1em;
  padding: 0.15em;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 6px;
  transition: background 0.3s ease;
}

.stat-item:hover {
  background: rgba(255, 255, 255, 0.15);
}

.stat-item-icon {
  font-size: 1.2rem;
  color: #00c9ff;
  text-shadow: 0 0 8px rgba(0, 201, 255, 0.7);
}

.stat-item:nth-child(odd) {
  background: rgba(255, 255, 255, 0.08);
}

.stat-item-text {
  color: #d1e3f8;
  font-weight: 500;
}

.stat-item-value {
  font-weight: bold;
  color: #00c9ff;
}

/* Tooltip style */
.stat-item::after {
  content: attr(data-tooltip);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 0.8rem;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: opacity 0.2s ease, transform 0.2s ease, visibility 0.2s;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.stat-item:hover::after {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -45px);
}
