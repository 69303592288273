/* Contenedor del modal */
.modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
}

.modal.is-open {
  display: flex;
}

/* Contenedor principal del modal */
.modal-container {
 
  width: 400px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

/* Botón de cierre */
.modal-close {
  align-self: flex-end;
  font-size: 20px;
  cursor: pointer;
  color: #777;
}

/* Contenedor para la imagen y detalles */
.content-container {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
}

/* Fondo de la imagen (patrón de gradiente) */
.content-img-nego {
  background: linear-gradient(to bottom, #3498db, #2980b9);
  border-radius: 10px;
  width: 200px;
  height: 200px;
  margin-right: 20px;
}

/* Estilos para el nombre */
.name-nego {
  /* Estilos para el nombre, como fuente o color, aquí */
}

/* Estilos para los botones de acciones */
.actions-nego {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
}

input[type="submit"] {
  background-color: #3498db;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  /* Estilos para tamaños de pantalla más pequeños */
  .content-container {
    grid-template-columns: 1fr;
  }

  .content-img-nego {
    width: 100%;
    margin-right: 0;
  }

 .content-img-nego img{
    background: linear-gradient(45deg, black, transparent);
    position: relative;
    min-width: 90%;
    min-height: 90%;
}
  .actions-nego {
    align-items: center;
  }
}


 /*.img-negocio */