body {
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}

article h1 {
  margin: 1rem 0;
}


.navigation {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  margin-left: 10vh;
 margin-right: 10vh;
}

.navigation img{
 
  height: 15vh;
}




.brand-name {
  text-decoration: none;
  color: black;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.navigation-menu {
  margin-left: auto;
}


.navigation-menu ul {
  display: flex;
  padding: 0;
}

.navigation-menu li {

  list-style-type: none;
  margin: 0 1rem;
}
.navigation-menu li a {

  text-decoration: none;
  /*display: block;*/
  
}

.navigation-menu img{
  max-width: 100px;
  max-height: 100px;
}



.hamburger {
  border: 0;
  height: 3em;
  width: 3em;
  padding: 0;
  border-radius: 50%;
  background: linear-gradient(135deg, #12897A, #1e3a8a, #0cbaba); /* Colores iniciales */
  background-size: 200% 200%;
  animation: gradient-move 4s ease infinite; /* Animación de movimiento */
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none; /* Oculto en pantallas grandes */
}

@keyframes gradient-move {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}



.navigation .hamburger img {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Hace que la imagen se ajuste al círculo */
  object-fit: cover; /* Escala la imagen para que ocupe el espacio sin distorsionarse */
}

.hamburger:hover {
  background-color: #167CBB;
}
















.navigation-menu ul li {
  margin: 0 0.5rem;
}

.nav-button {
  background: linear-gradient(to bottom, #444, #333);
  color: #00a8e8;
  border: none;
  border-radius: 8px;
  padding: 10px 15px;
  margin: inherit;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 
    0 1px 0 rgba(255,255,255,0.1) inset,
    0 -1px 0 rgba(0,0,0,0.2) inset,
    0 2px 4px rgba(0,0,0,0.4);
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.nav-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  background: linear-gradient(to bottom, rgba(255,255,255,0.1), transparent);
}

.nav-button:hover {
  background: linear-gradient(to bottom, #555, #444);
  /*transform: translateY(-2px);*/
  box-shadow: 
    0 1px 0 rgba(255,255,255,0.1) inset,
    0 -1px 0 rgba(0,0,0,0.2) inset,
    0 4px 6px rgba(0,0,0,0.4);
}

.nav-button:active {
  /*transform: translateY(1px);*/
  box-shadow: 
    0 1px 0 rgba(255,255,255,0.1) inset,
    0 -1px 0 rgba(0,0,0,0.2) inset,
    0 1px 2px rgba(0,0,0,0.4);
}

.nav-button-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  vertical-align: middle;
}

.nav-button-iconos {
  display: inline-block;
  padding: 0.5em;
  margin-right: 5px;
  vertical-align: middle;
}

.dark .nav-button {
  background: linear-gradient(to bottom, #222, #111);
  color: #00a8e8;
}

.dark .nav-button:hover {
  background: linear-gradient(to bottom, #333, #222);
}







.icon-l-em {
  color: #00a8e8;

width:1em;
height:1em;
}


























































































































































































































@media screen and (max-width: 768px) {
  .container {
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}


.navigation {
  height: 60px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  color: black;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
  margin-left: 1vh;
 margin-right: 1vh;
}
.navigation img{
  width: 15vw;
  height: 15vh;
}

.navigation-menu img{
  max-width: 60px;
  max-height: 60px;
}



.navigation-menu {
  margin-left: auto;
}


.navigation-menu ul {
  display: flex;
  padding: 0;
}
.navigation-menu li {
 
  list-style-type: none;
  margin: 0 0.2rem;
  text-align: ;
}
.navigation-menu li a {
  text-decoration: none;
  display: grid;
  width: 100%;
}

.navigation-menu img{
  max-width: 100px;
  max-height: 100px;
}



 




  .hamburger {
    display: block;
  }



  .navigation-menu ul {
    display: none;
  }




  .navigation-menu.expanded ul {
    display: block;
  }

  .navigation-menu ul {
    z-index: 10;
   position: absolute;
    top: 3em;
    right: 0;
    flex-direction: column;
    width: auto;
    height: calc(100% );
    border-top: 1px solid black;

  }
  .navigation-menu li {
    z-index: 10;
    text-align: center;
    margin: 0;
  background-color: #2642af;

  }
  .navigation-menu li a {

    width: auto;
    padding: 0.2rem 0;
  }
  .navigation-menu li:hover {
    /*background-color: #eee;*/
     background-color:#167CBB;
  }




.icon-l-em {
width:1.5em;
height:1.5em;
}















}










































