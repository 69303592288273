.horse-item {
  background: linear-gradient(135deg, #111, #333);
  border: 2px solid #222;
  border-radius: 12px;
  padding: 1rem;
  margin: 0.5rem 0;
  color: #f1f1f1;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.5), 0 0 10px rgba(255, 0, 255, 0.3);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.horse-item:hover {
  transform: scale(1.05);
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.8), 0 0 20px rgba(255, 0, 255, 0.6);
}

.horse-header {
  display: flex;
  justify-content: space-between;
  font-family: 'Orbitron', sans-serif;
  font-size: 1.2rem;
  color: #00e6e6;
}

.horse-attributes {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}


.horse-attributes span {
  position: relative;
  font-family: 'Orbitron', sans-serif;
  color: #ff00ff;
  font-size: 1rem;
  padding: 1rem;
  border-radius: 6px;
  text-shadow: 0 0 10px rgba(255, 0, 255, 0.8);
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  transition: transform 0.2s ease, text-shadow 0.3s ease;
}

.horse-attributes span svg {
  width: 3rem;
  height: 3rem;
  filter: drop-shadow(0 0 5px #0ff);
  position: relative;
  z-index: 1;
}

.horse-attributes span::after {
  content: attr(data-value);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.4rem;
  color: #fff;
  z-index: 2;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.9), 0 0 5px rgba(0, 255, 255, 0.7);
  transition: text-shadow 0.3s ease, transform 0.2s ease;
}

/* Efecto Hover y Focus */
.horse-attributes span:hover::after,
.horse-attributes span:focus::after {
  text-shadow: 0 0 15px rgba(255, 255, 255, 1), 0 0 10px rgba(255, 0, 255, 0.9), 0 0 25px rgba(0, 255, 255, 0.8);
  transform: translate(-50%, -50%) scale(1.2);
}

/* Opcional: Animación para Click */
.horse-attributes span:active::after {
  text-shadow: 0 0 20px rgba(255, 255, 255, 1), 0 0 15px rgba(255, 0, 255, 1), 0 0 30px rgba(0, 255, 255, 1);
  transform: translate(-50%, -50%) scale(1.3);
}


.horse-attributes span {
  position: relative;
  font-family: 'Orbitron', sans-serif;
  font-size: 1rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.7); /* Fondo oscuro para contraste */
  border-radius: 6px;
  transition: transform 0.2s ease, text-shadow 0.3s ease;
}

/* Estilo para el Ícono */
.horse-attributes span svg {
  width: 2.5rem;
  height: 2.5rem;
  fill: #0ff; /* Color base del ícono (cyan) */
  opacity: 0.8;
  margin-right: 0.5rem;
  transition: fill 0.3s ease;
}

/* Estilo para el Número */
.horse-attributes span::after {
  content: attr(data-value);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: #ff00ff; /* Color brillante para el número (fucsia) */
  z-index: 1;
  text-shadow: 0 0 8px rgba(255, 0, 255, 0.7), 0 0 12px rgba(255, 0, 255, 0.9);
  transition: text-shadow 0.3s ease, transform 0.2s ease;
}

/* Efecto Hover para Resaltar el Número */
.horse-attributes span:hover::after,
.horse-attributes span:focus::after {
  text-shadow: 0 0 10px rgba(255, 255, 255, 1), 0 0 15px rgba(255, 0, 255, 1), 0 0 20px rgba(0, 255, 255, 0.8);
  transform: translate(-30%, -50%) scale(1.1);
}

/* Efecto Click (Active) */
.horse-attributes span:active::after {
  text-shadow: 0 0 15px rgba(255, 255, 255, 1), 0 0 20px rgba(255, 0, 255, 1), 0 0 30px rgba(0, 255, 255, 1);
  transform: translate(-30%, -50%) scale(1.15);
}







.horse-id {
  color: deepskyblue;
  font-weight: bold;
}

.horse-type {
  color: #00ffff;
  font-style: italic;
}

.horse-speed, .horse-endurance, .horse-agility {
  position: relative;
  padding: 0.5rem;
  border: 1px solid #333;
  border-radius: 5px;
  background: linear-gradient(135deg, #101, #202);
  box-shadow: inset 0 0 5px #0ff, 0 0 10px rgba(0, 255, 255, 0.6);
}

.horse-speed::before, .horse-endurance::before, .horse-agility::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 0.4;
  background: radial-gradient(circle, rgba(255,255,255,0.1), transparent);
}

.horse-item:hover .horse-speed,
.horse-item:hover .horse-endurance,
.horse-item:hover .horse-agility {
  background: linear-gradient(135deg, #222, #333);
  box-shadow: 0 0 15px rgba(255, 0, 255, 0.6), inset 0 0 15px rgba(0, 255, 255, 0.6);
}
