/*.trapecio_info {
  z-index: 20;
  display: grid;
  position: absolute;
  align-self: center;
  right: 0%;
  left: 20vw;
  bottom: 3%;
  width: auto;
  height:55%;
  border-bottom: 2vw solid #222;
  border-left: 5vw solid transparent;
  border-right: 5vw solid transparent;
  object-fit: cover;
  transform: scaleX(-1);
  

}*/

.trapecio_info {
    z-index: 10;
    display: grid;
    position: absolute;
    bottom: 7vh;
    width: auto;
    border-bottom: 3vw solid #2196f3;
    /* border-left: 5vw solid transparent; */
    border-right: 5vw solid transparent;
    object-fit: cover;
    transform: scaleX(-1);
}


.trapecio_info div{
  display: inline-flex;
background-color: #2196f3;
transform: scaleX(-1);

}


 .trapecio_info svg{
width: 1.2rem;
height: 1.2rem;
fill:white;

}





/* Dispositivos pequeños (móviles en orientación vertical, 320px - 480px) */
@media only screen and (max-width: 480px) {
}

/* Dispositivos medianos (tabletas en orientación vertical, 481px - 768px) */
@media only screen and (max-width: 768px) {
}

/* Dispositivos grandes (tabletas en orientación horizontal y pantallas pequeñas de escritorio, 769px - 1024px) */
@media only screen and (max-width: 1024px) {

}

/* Dispositivos extra grandes (pantallas de escritorio grandes, 1025px en adelante) */
@media only screen and (min-width: 1025px) {

}
