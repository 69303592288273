.modal {
  position: fixed;
  z-index: 5;
  top: 5vh;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  align-content: center;
  display: none;
}




.modal-containerace {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: yellow;
  font-size: 10px;
  padding: 5vw;
  border-radius: 10px;
  z-index: 9999;
  align-content: center;
}


/* Estilos del modal (lo que ya tienes) */
.modal-container-race-results {
    position: fixed; /* Mantener en la misma posición */
    padding: 1rem;
    width: auto; /* Ajustar al contenido */
    max-width: 90%; /* Limitar el ancho máximo para pantallas grandes */
    min-height: 90%;
    max-height: 90vh; /* Limitar altura máxima */
    overflow-y: auto;
    background-color: #222;
    border-radius: 8px; /* Esquinas redondeadas */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Sombra */
    transition: all 0.3s ease; /* Suavizado de transición */
    top: 50%; /* Centrar verticalmente */
    left: 50%; /* Centrar horizontalmente */
    transform: translate(-50%, -50%); /* Alinear el modal en el centro */
}

/* Estilo para el contenedor que tiene el scroll */
.modal-container-race-results::-webkit-scrollbar {
    width: 1em; /* Ancho de la barra de scroll */
}

/* Fondo de la barra de scroll */
.modal-container-race-results::-webkit-scrollbar-track {
    background: #167CBB; /* Color de fondo de la barra */
    border-radius: 10px; /* Redondear la pista */
}

/* Color del pulgar de la barra de scroll */
.modal-container-race-results::-webkit-scrollbar-thumb {
    background: #167CBB; /* Color del pulgar (la parte que se arrastra) */
    border-radius: 10px; /* Redondear el pulgar */
    border: 2px solid #167CBB; /* Crear un borde entre el pulgar y la pista */
}

/* Color del pulgar al pasar el mouse por encima */
.modal-container-race-results::-webkit-scrollbar-thumb:hover {
    background: #167CBB; /* Cambiar el color cuando el mouse pasa por encima */
}

/* Alternativa para navegadores que no soportan -webkit-scrollbar */
.modal-container-race-results {
    scrollbar-width: thin; /* Para Firefox: hacer el scroll más delgado */
    scrollbar-color: #888 #444; /* Para Firefox: thumb y track */
}


.modal-close-race {
  z-index: 100;
  position: absolute;
  top: 1rem;
  right: 1rem;
  text-decoration-color: white;
}


.results-races {
  padding: 10px;
  margin-bottom: 10px;
}

.results-races table {
  width: 90%;
  margin: 10%;

  height: auto;
  border-collapse: collapse;
}

.results-races th, .results-races td {
  padding: 5px;
  border: 1px solid #ccc;
}

.results-races th {
  background-color: #222;
  font-weight: bold;
}

.results-races td {
  text-align: left;
}

.race-name {

}

p.race-name{
  color: #ccc;
  text-align: left;
  font-weight: bold;
}

.icono-races {
  width: 30%; /* ajusta el ancho según tus necesidades */
  height: 30%; /* ajusta la altura según tus necesidades */
  border-radius: 5px;
  object-fit: cover;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/*.icono-races.hovered {
  transform: scale(1.5); 
}
*/



.card-info-resultados {
  position: relative;
  width: 100%;
  height: auto;
  background: #222;
  display: flex;

}


.Resultados {
  width: 80%;
  border-collapse: collapse;
  padding: 10%;
  margin: auto;
}

.Resultados th,
.Resultados td {
  padding: 8px;
  text-align: left;
  border-bottom: 0px solid #ddd;
}

.Resultados th {
  background-color: black;
}

.Resultados h2,
.Resultados h1,
.Resultados h4,
.Resultados h5,
.Resultados span {
  margin: 0;
}

.Resultados .icon {
  width: 10em;
  height: 10em;
}

.Resultados .icono-races {
  width: 7em;
  height: auto;
}

.Resultados .race-lane-background {
  background-size: cover;
  background-repeat: repeat-x;
  height: 80%;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
  animation: moveBackground 2s linear infinite;
}

