/* Contenedor principal de la tabla */
.resultados {
  width: 92%;
  border-collapse: collapse;
  margin: 1rem 0;
  font-size: 0.9rem;
  color: #fff;
}

.resultados th, .resultados td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.resultados th {
  background-color: #444;
  color: white;
}

.icon-text {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.icon-text img {
  width: 1.5rem;
  height: 1.5rem;
}

.equine-image {
  max-width: 100px;
  height: auto;
}

@media only screen and (max-width: 480px) {
  .resultados {
    font-size: 0.8rem;
  }

  .resultados th, .resultados td {
    padding: 6px;
  }

  .icon-text img {
    width: 1rem;
    height: 1rem;
  }

  .equine-image {
    max-width: 80px;
  }
}

@media only screen and (max-width: 768px) {
  .resultados {
    font-size: 0.85rem;
  }

  .resultados th, .resultados td {
    padding: 7px;
  }

  .icon-text img {
    width: 1.2rem;
    height: 1.2rem;
  }

  .equine-image {
    max-width: 90px;
  }
}
