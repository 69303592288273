/* CONTAINER */

.modal-race-container-info-ballo {

  display: flex;
  position: absolute; /* Fijar la posición en la pantalla */
  top: 50%; /* Centrar verticalmente */
  left: 50%; /* Centrar horizontalmente */
  transform: translate(-50%, -50%); /* Ajustar el centro exacto */
  
  min-width: 90vw;
  max-width: 90vw;
  min-height: 90vh;
  max-height: 90vh;
  
  border-radius: 20%; /* Bordes redondeados */
  overflow-y: auto; /* Scroll para contenido largo */
  
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Sombra para darle más estilo */
  padding-bottom: 1rem; /* Añadir un poco de espacio interior */
}





.data-container{
  justify-content: center;
}



.modal-race-close {
  z-index: 10;
  position: relative;
  top: 1%;
  right: 2%;
  text-decoration-color: white;
background-color: red;
  padding: 0.5%;
  font-size: x-large;
}

.modal.is-open {
  display: flex;
}




.modal-race-container-info-ballo .card-race-info {
  position: absolute;
  top: 50%; /* Centrar verticalmente */
  left: 50%; /* Centrar horizontalmente */
  transform: translate(-50%, -50%); /* Ajustar el centro con respecto al padre */

  width: 50vw;
  max-width: fit-content;
  height: auto;
  background: #2e2e2e;
  border-radius: 20%;
  display: flex;
}


/**/
/**/
/*Buton Mail*/
/*Buton Mail*/
/**/
/**/
.button-container-mail {
  position: relative; /* Permite el posicionamiento absoluto del badge */
  display: inline-block; /* Ajusta el contenedor al tamaño del botón */
  margin-left: 10px ; /* Espacio entre el icono y el texto */

}

button.mail {
  background-color: transparent;
  border: 2px solid #007BFF; /* Borde azul para el botón */
  border-radius: 5px; /* Bordes redondeados */
  padding: 0px; /* Relleno alrededor del contenido del botón */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease, background-color 0.2s ease, border-color 0.2s ease;
}

button.mail:hover {
  transform: scale(1.05);
  background-color: #007BFF; /* Color de fondo azul en hover */
  border-color: #0056b3; /* Color del borde más oscuro en hover */
}

button.mail:active {
  transform: scale(0.95);
}

button.mail img {
  width: 3rem; /* Ancho de la imagen del icono */
  height: auto; /* Mantiene la proporción de la imagen */
  margin-left: 0px ; /* Espacio entre el icono y el texto */
}

button.mail .img-negocio {
  display: block;
  max-width: 100%;
  height: auto;
}

.notification-badge {
  position: absolute;
  top: -10px; /* Ajusta la posición vertical según sea necesario */
  right: -10px; /* Ajusta la posición horizontal según sea necesario */
  background-color: red; /* Color de fondo del badge */
  color: white; /* Color del texto del badge */
  border-radius: 50%; /* Hace que el badge sea redondeado */
  width: 1.4rem; /* Ancho del badge */
  height: 1.4rem; /* Alto del badge */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.7rem; /* Tamaño del texto */
  font-weight: bold; /* Peso del texto */
  opacity: 1; /* Asegúrate de que el badge sea visible por defecto */
  transition: opacity 0.2s ease; /* Transición suave para la visibilidad */
}

.button-container:hover .notification-badge {
  opacity: 0; /* Oculta el badge cuando el botón está en hover */
}


/**/
/**/

/*Buton Mail*/
/**/






.race-submit-button {
   position: absolute;
  right: 2em;
  bottom: 0px;
  cursor: pointer;
  background-color: #007BFF;
  border: 50%;
  border-radius: 20%; /* Bordes redondeados */
  
  color: black;
  padding: 10px 20px;
  transition: background-color 0.3s ease; /* Transición suave para cambio de color */
  width: 30%;
  z-index: 14 ;
}

.race-submit-button:hover {
  background-color: gold; /* Color al pasar el ratón */
}

.race-submit-button:active {
  background-color: #5ba7e7a3; /* Color al hacer clic o deshacer hover */
}






































.card-race-info .content-race {
  position: relative;
  width: max-content;
  height: auto; /* Cambié min y max por width y height */
  display: flex;
  flex-direction: column;
  border-radius: 30%;
  text-align: center;
  background: #2e2e2e; 
  overflow: auto;
  z-index: 10;
  padding: 1em 0em 3em 0em;
}



.card-race-info .content-race-img {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  border-radius: 25%;
  margin: 0 auto;
  transform: scaleX(-1);
  z-index: 1;
}
























.card-race-info .name{
  display: flex;
  align-items: center;
  left: 5em;
  top: -0.5em;
position: relative;
object-fit: cover;
}





/* Estilos para las imágenes */
.img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  width: 80%;
}

.img-container img {
  max-width: 3rem;
  height: auto;
}



.card-race-info .content-race .icon {
  color: var(--color);
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  background: #002c97;
  box-shadow: 0 0 0 4px #002c97,
    0 0 0 6px var(--color);
  transition: 0.5s ease-in-out;

}

.card-race-info:hover .content-race .icon {
  background: var(--color);
  color: #2e2e2e;
  box-shadow: 0 0 0 4px #2e2e2e,
    0 0 0 300px var(--color);
}






.card-race-info-c {
  position: relative;
  width: 100%;
  height: 100%;
  background: #ffff;
  display: flex;

}


.card-race-info {
  position: relative;
  width: 60vw;
  height: 80vh;
  display: flex;

}
.card-race-info-c .content-race {
  position: relative;
  width: auto;
  height: 100%;
  padding: 1px 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #854D0D;
  overflow: hidden;
  z-index: 10;
}



.actions-race {
  position: relative;
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  height: 100%;
  background: #2e2e2e;
  display: flex;
  justify-content: space-around;
  visibility: hidden; /* Oculta el elemento */
}

.actions-race input {
  justify-self: center; 
}
























































/* Tablets pequeñas y móviles grandes (mínimo 576px) */
@media screen and (min-width: 200px) {
 .modal-race-container-info-ballo .card-race-info .content-race-img-common .caballoimg {
  max-width: 50vw; /* Establecer un tamaño máximo para limitar el crecimiento */
  max-height: auto;
}

.modal-race-container-info-ballo .card-race-info  {
  min-height:auto ;
  width: 100%;
  max-height: 80vh; /* Cambié min y max por width y height */
  border-radius: 10%;
  overflow: hidden;

}
.modal-race-container-info-ballo .card-info .content-img img.Caballo{
top: 0.5em;
min-width: 60%;
max-height: 60%;

}

.card-race-info .content-race {
  /*overflow-y: auto;  Cambié de scroll a auto para mostrar el scroll solo cuando sea necesario */
  overflow: auto;
}

.race-submit-button {
  left: 2em;
  width: 40%;
}

.card-race-info .name{
  left: 0em;
  top: -0.5em;
}

}

/* Estilos predeterminados para pantallas pequeñas (móviles primero) */

/* Tablets pequeñas y móviles grandes (mínimo 576px) */
@media screen and (min-width: 406px) {
 .modal-race-container-info-ballo .card-race-info .content-race-img-common .caballoimg {
  max-width: 50vw; /* Establecer un tamaño máximo para limitar el crecimiento */
  max-height: auto;
}

.modal-race-container-info-ballo .card-race-info  {
  min-height:auto ;
  width: 95%;
  max-height: 80vh; /* Cambié min y max por width y height */
  border-radius: 10%;
  overflow: hidden;

}
.modal-race-container-info-ballo .card-info .content-img img.Caballo{
top: 0.5em;
min-width: 60%;
max-height: 60%;

}

.card-race-info .content-race {
  /*overflow-y: auto;  Cambié de scroll a auto para mostrar el scroll solo cuando sea necesario */
  overflow-x: hidden;
  /*overflow: auto;*/
}

.modal-race-close {
  padding: 1.5%;
}





}



/* Tablets pequeñas y móviles grandes (mínimo 576px) */
@media screen and (min-width: 576px) {
  .modal-race-container-info-ballo .card-race-info .content-race-img-common .caballoimg {
  max-width: 50vw; /* Establecer un tamaño máximo para limitar el crecimiento */
  max-height: auto;
}

.modal-race-container-info-ballo .card-race-info  {
  min-height:auto ;
  width: 95%;
  max-height: 80vh; /* Cambié min y max por width y height */
  border-radius: 10%;
  overflow: hidden;

}
.modal-race-container-info-ballo .card-info .content-img img.Caballo{
top: 0.5em;
min-width: 60%;
max-height: 60%;

}

.card-race-info .content-race {
  /*overflow-y: auto;  Cambié de scroll a auto para mostrar el scroll solo cuando sea necesario */
  overflow-x: hidden;
  /*overflow: auto;*/
}

 
}




/* Tamaño pequeño */
@media screen and (min-width: 768px)  {


.modal-race-container-info-ballo .card-race-info .content-race-img-common .caballoimg {
  max-width: 50vw; /* Establecer un tamaño máximo para limitar el crecimiento */
  max-height: auto;
}

.modal-race-container-info-ballo .card-race-info  {
  min-height:auto ;
  width: 95%;
  max-height: 80vh; /* Cambié min y max por width y height */
  border-radius: 10%;
  overflow: hidden;

}
.modal-race-container-info-ballo .card-info .content-img img.Caballo{
top: 0.5em;
min-width: 60%;
max-height: 60%;

}

.card-race-info .content-race {
  /*overflow-y: auto;  Cambié de scroll a auto para mostrar el scroll solo cuando sea necesario */
  overflow-x: hidden;
  /*overflow: auto;*/
}


}


/* Tamaño mediano */
@media screen and (min-width: 628px) and (max-width: 1049px) {
.modal-race-container-info-ballo .card-race-info .content-race-img-common .caballoimg {
  max-width: 50vw; /* Establecer un tamaño máximo para limitar el crecimiento */
  max-height: 40vh;
}

.modal-race-container-info-ballo .card-race-info  {
  min-height:auto ;
  width: 70%;
  max-height: 80vh; /* Cambié min y max por width y height */
  border-radius: 10%;
  overflow: hidden;

}
.modal-race-container-info-ballo .card-info .content-img img.Caballo{
top: 0.5em;
min-width: 60%;
max-height: 60%;

}

.card-race-info .content-race {
  /*overflow-y: auto;  Cambié de scroll a auto para mostrar el scroll solo cuando sea necesario */
  overflow-x: hidden;
  /*overflow: auto;*/
}




}

/* Tamaño widescreen */
@media screen and (min-width: 1050px){
.modal-race-container-info-ballo .card-race-info .content-race-img-common .caballoimg {
  max-width: 20vw; /* Establecer un tamaño máximo para limitar el crecimiento */
  max-height: 40vh;
}


.modal-race-container-info-ballo .card-race-info  {
  min-height:auto ;
  width: 60%;
  max-height: 80vh; /* Cambié min y max por width y height */
  border-radius: 10%;
  overflow: hidden;

}

.modal-race-container-info-ballo .card-info .content-img img.Caballo{
top: 0.5em;
min-width: 52%;

}


.card-race-info .content-race {
  /*overflow-y: auto;  Cambié de scroll a auto para mostrar el scroll solo cuando sea necesario */
  overflow-x: hidden;
  /*overflow: auto;*/
}



}
/* Tamaño widescreen */
@media screen and (min-width: 1200px) {
.modal-race-container-info-ballo .card-race-info .content-race-img-common .caballoimg {
  max-width: 20vw; /* Establecer un tamaño máximo para limitar el crecimiento */
  max-height: 40vh;
}

.modal-race-container-info-ballo .card-race-info  {
  min-height:auto ;
  width: 50%;
  max-height: 80vh; /* Cambié min y max por width y height */
  border-radius: 10%;
  overflow: hidden;

}

.modal-race-container-info-ballo .card-race-info .content-race {
  min-height:auto ;
  max-width: 60vw;
  max-height: 80vh; /* Cambié min y max por width y height */
  border-radius: 25%;
  margin: 0 auto;

}

.modal-race-container-info-ballo .card-info .content-img img.Caballo{
top: 0.5em;
width: 20%;

}

.card-race-info .content-race {
  /*overflow-y: auto;  Cambié de scroll a auto para mostrar el scroll solo cuando sea necesario */
  overflow-x: hidden;
  /*overflow: auto;*/
}

/*
.trapecio-inforace {
  width: 70%;
  border-left: 1em solid transparent;
  transform: scaleX(-1);
  margin-top: -4em;  
  margin-bottom: 0.5em;/*Ajustar el margen superior según sea necesario */
/*}*/



}