/* modalwallet.css */
.modal-walletBHRT {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, 0.5);*/
  background-color: #222;
  z-index: 9999;
}

.modal-walletBHRT.is-open {
  display: block;
}

.modal-walletBHRT-container {
  position: relative;
  width: 80%;
  max-width: 80%;
  margin: 20px auto;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
}

.modal-walletBHR-container {
  position: relative;
  width: 80%;
  max-width: 80%;
  margin: 20px auto;
  background-color: #222;
  background: #222;
  padding: 20px;
  border-radius: 8px;
}

.modal-walletBHRT-close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
}

.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.content-balance {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}

.content-details-BHRT {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.botones-wallet-canje-BHRT {
  display: inline-block;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.botones-wallet-canje-BHRT button {
  width: 150px;
  height: 150px;
  display: inline-block;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  background-color: #222;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.botones-wallet-canje-BHRT button:hover {
  background-color: #dcdcdc;
}

/* Adjust styles as needed for the claim button at the bottom */
.claim-button {
  width: 200px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  background-color: #2ecc71;
  border: none;
  border-radius: 8px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.claim-button:hover {
  background-color: #27ae60;
}


/* modalwallet.css */
.botones-wallet-canje-BHRT button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.botones-wallet-canje-BHRT button spam {
  align-self: flex-start;
  margin-bottom: auto;
}

.botones-wallet-canje-BHRT button img {
  width: 80%;
  height: 80%;
  margin-top: auto;
}

.botones-wallet-canje-BHRT button span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  z-index: 999;
}




/* Añade estos estilos adicionales */

/* Ajustes para el balance */
.content-balance {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}

/* Ajustes para los botones */
.botones-wallet-canje-BHRT {
  display:block;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.botones-wallet-canje-BHRT button {
  width: 150px;
  height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}




/* modalwallet.css */

/* Estilos adicionales */
.content-balance {
  position: absolute;
  top: 20px; /* Ajustado para el espacio adicional */
  left: 50%;
  transform: translateX(-50%);
}

.botones-wallet-canje-BHRT {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px; /* Espacio adicional */
  margin-bottom: 20px; /* Espacio adicional */
}

.botones-wallet-canje-BHRT button {
  width: 150px;
  height: 150px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* ...otros estilos existentes... */

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
  .content-balance {
    position: static;
    transform: none;
    text-align: center;
    margin-bottom: 20px;
  }

  .botones-wallet-canje-BHRT {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .botones-wallet-canje-BHRT button {
    width: 45%;
    height: 120px;
  }

  .claim-button {
    width: 90%;
    height: 40px;
  }
}


/* Estilos para dispositivos móviles */
/*@media (max-width: 768px) {
  .content-balance {
    position: static;
    transform: none;
    text-align: center;
    margin-bottom: 20px;
  }

  .botones-wallet-canje-BHRT {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }

  .botones-wallet-canje-BHRT button {
    width: 45%;
    height: 120px;
  }

  .claim-button {
    width: 90%;
    height: 40px;
  }
}
*/

/* modalwallet.css */
/* ... otros estilos ... */


.content-balanceBH {
  font-family: 'Press Start 2P', cursive; /* Fuente estilo videojuego */
  font-size: 16px; /* Tamaño de fuente */
  color: #fff; /* Color de texto */
  text-shadow: 1px 1px #000; /* Sombra de texto */
  padding: 10px; /* Ajuste de espaciado */
  background-color: #222; /* Fondo de pantalla */
  border: 2px solid #fff; /* Borde */
  border-radius: 5px; /* Borde redondeado */

}

/* Estilos para el último botón "Claim Balance" de pantalla Atari */
.claim-button {
  font-family: 'Press Start 2P', cursive; /* Fuente estilo videojuego */
  font-size: 14px; /* Tamaño de fuente */
  color: #fff; /* Color de texto */
  text-shadow: 1px 1px #000; /* Sombra de texto */
  padding: 10px 20px; /* Ajuste de espaciado */
  background-color: #000; /* Fondo de pantalla */
  border: 2px solid #fff; /* Borde */
  border-radius: 5px; /* Borde redondeado */
  cursor: pointer; /* Cursor estilo puntero */
  transition: background-color 0.3s ease; /* Transición de color de fondo */
}

.claim-button:hover {
  background-color: #444; /* Color de fondo al pasar el ratón */
}