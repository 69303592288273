.modal {
  position: fixed;
  z-index: 109;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: none;
  justify-content: center;
  align-items: center;
}
.modal h2 {
 font-size: 1rem;
 padding-bottom: 1vh;
}

modal input{
  padding-bottom: 1vh;

}


/*
.modal img{
position: relative;
min-width: 35%;
min-height: 35%;

}
*/

.modal img.icon{
position: relative;
min-width: 15%;
}

.modal-container {
  position: relative;
  padding: 1rem;
  min-width: 320px;
  max-width: 480px;
  min-height: 200px;
  max-height: 600px;
  overflow-y: auto;
  background-color: #000;
}


.modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

/*.modal.is-open {
  display: flex;
  min-width: 95%;
}
*/
.modal.is-open {
    display: flex;
    justify-content: center;  /* Centra horizontalmente */
    align-items: center;      /* Centra verticalmente */
    max-width: 100%;
    height: 100vh;            /* Asegúrate de que el contenedor ocupe toda la altura de la ventana */
    position: fixed;          /* Fija el contenedor para que se superponga al contenido */
    top: 0;                   /* Posiciona en la parte superior de la ventana */
    left: 0;                  /* Posiciona en la parte izquierda de la ventana */
    right: 0;                 /* Asegura que ocupe toda la anchura */
    bottom: 0;                /* Asegura que ocupe toda la altura */
    background-color: rgba(0, 0, 0, 0.5);  /* Fondo semitransparente para superposición */
    z-index: 9999;            /* Asegura que esté encima de otros elementos */
}
