/* Mobile-first styles */
.ModalPatroInv {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 0 10px;
}

.ModalPatroInv-content {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
  text-align: left;
}


/* Estilos principales del modal */
.ModalPatroInv {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ModalPatroInv-content {
  position: relative; /* Necesario para posicionar el botón de cierre dentro del modal */
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 500px;
  text-align: left;
}

/* Estilos para el botón de cierre */
.ModalPatroInv-closeButton {
  position: absolute;
  right: 0; /* Asegura que el botón esté alineado a la derecha */
  top: 50%; /* Coloca el centro del botón en la mitad del eje vertical */
  transform: translate(0, -50%); /* Ajusta el botón hacia arriba en el eje vertical */
  background-color: indianred;
  color: red;
  border: none;
  font-size: 1.5em;
  height: 50%; /* Ocupa el 50% de la altura del modal */
  width: 50px; /* Ancho fijo */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  /*border-top-right-radius: 8px;*/
  /*border-bottom-right-radius: 8px;*/
  display: flex;
  align-items: center;
  transform: translate(0, 50%) scale(1.05); /* Ligero aumento al hacer hover */

  justify-content: center;
}


.ModalPatroInv-closeButton:hover {
  background-color: #b30000; /* Rojo más oscuro al hacer hover */
}

.horse-item {
  max-width: 100%;
  padding: 8px;
  border: 1px solid #dddddd;
  border-radius: 8px;
  margin: 10px 0;
  background-color: #f9f9f9;
}

.horse-header {
  font-weight: bold;
  margin-bottom: 5px;
}

.horse-id, .horse-type {
  color: #white;
  font-size: 0.9em;
}

.letter-text {
  color: #555555;
  font-size: 0.95em;
  margin-top: 10px;
}

.action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #007bff;
  background-color: #FBBF24; 

  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  max-width: 250px;
  margin: 10px auto;
}

.action-button span {
  background-color:#10B981 ;
  align-items: center;
  gap: 5px;
  font-size: 0.85em;
}

.action-button h2 {
  margin: 5px 0;
  font-size: 1em;
}

.action-button p {
  font-size: 0.85em;
  margin: 3px 0;
}

.coin {
  width: 18px;
  height: 18px;
}

.action-button:hover {
  background-color: #0056b3;
  background-color: #f59e0b; /* Amarillo más oscuro al pasar el ratón */
  
}

.ModalPatroInv-closeButton {
  background-color: indianred;

  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: #333333;
}

/* Desktop styles */
@media (min-width: 768px) {
  .ModalPatroInv-content {
    padding: 20px;
    max-width: 500px;
  }

  .horse-item {
    max-width: 250px;
    margin: 10px auto;
  }

  .action-button {
    max-width: 300px;
    padding: 12px 18px;
  }

  .action-button span {
    font-size: 0.9em;
  }

  .action-button h2 {
    font-size: 1.1em;
  }

  .action-button p {
    font-size: 0.9em;
  }

  .coin {
    width: 20px;
    height: 20px;
  }
}

/* Large desktop styles */
@media (min-width: 1024px) {
  .ModalPatroInv-content {
    padding: 25px;
    max-width: 600px;
  }

  .horse-item {
    max-width: 300px;
    margin: 15px auto;
  }

  .action-button {
    max-width: 350px;
    padding: 15px 20px;
  }
}
