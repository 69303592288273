/* Card base style */
.race-item-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 250px; /* Ancho fijo */
  height: 300px; /* Alto fijo */
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff; /* Texto en blanco para mayor contraste */
  margin-top: 5em;
}

/* Estados de fondo con colores de alto contraste */
.race-highlight {
  background-color: #167CBB; /* Color oscuro dorado */
}





.race-baselight {
  background-color: darkcyan; /* Gris oscuro para el estado básico */
}

/* Hover effect */
.race-item-card:hover {
  transform: scale(1.02);
}

/* Header section */
.race-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.1rem;
}

.raceid {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Body section */
.race-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.name {
  font-size: 1.2rem;
  font-weight: bold;
}

.race-participants {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.status-text {
  font-size: 0.9rem;
  font-weight: 500;
}

/* Footer section */
.race-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle, .semaforo-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 5px;
}

/* Estado de color en el footer */
.circle.green {
  background-color: green;
}

.circle.red {
  background-color: red;
}

.semaforo-circle.black {
  background-color: black;
}

/* Texto adicional */
.tiempo-faltante {
  font-size: 1rem;
  font-weight: bold;
}


.tiempo-faltante {
    color: #fbbf24;
    background-color: black;
    padding: 0.5em;
    border-radius:0.5em ;
    font-size: 1em;
    margin-top: -1em;
    text-align: center;
}
.tiempo-faltante {
    display: flex;
    margin: 0;
    padding-top: 0;
    position: relative;
    top: -3em;
    z-index: 500;
}





.race-item-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  width: 250px;
  height: 300px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: #fff;
  /*background: url('./image/backgroundballos/Back_Ground_Races_List.webp'), linear-gradient(135deg, #1d4d9a, #0a2b60);  /*Textura + degradado */
  background-blend-mode: overlay;
}
.race-item-card:hover {
  transform: scale(1.02);
}

.race-item-card::before {
/*  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
*/  /*background-image: url('./image/backgroundballos/Back_Ground_Races_List.webp'); /* Icono de bandera en el fondo 
  background-size: 30px 30px;
  opacity: 0.1; /* Transparencia baja */
}

.race-header, .race-body, .race-footer {
  position: relative; /* Para que el contenido esté encima del fondo */
}
