.cart-container {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  width: 100%;
}

.cart-containera {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  width: 70%;
}



.button-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 70%;
}


.titulo-tipo-nft{
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 90%;
}

.buy-button-container {
  margin-top: 10px;
}



/*.utility-group-modal {
  position: relative;
  top: 25vh;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  background-color: #fff;
  
}

.utility-group-modal .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}



.market{
  color: black;
}
*/


 img.coin{
  z-index: 1;
    width: 2em;
    height: 2em;
}

.utility-group-modal .item button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.utility-group-modal .item button:hover {
  background-color: #0056b3;
}







/* Estilos base para los botones */
.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
  width: 70%;
}

.button-container button {
  flex: none;
  width: 23%;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
}

.button-container button:hover {
  background-color: #0056b3;
}

/* Media query para pantallas más pequeñas */
@media (max-width: 768px) {
  .button-container {
    width: 100%;
    gap:1%; /* Ocupar todo el ancho disponible */
  }

  .button-container button {
    flex: none; /* Deshabilitar el crecimiento */
    width: 40%; /* Ocupar todo el ancho del contenedor */
    margin-bottom: 1px; /* Espacio entre botones en pantallas pequeñas */
  }
}
