.wallet {
  display: flex;
  padding-right: 0.2em;
  padding-bottom: 0.2em;
  align-items: center;
  /*padding-right: 1px;*/
  cursor: pointer;
  flex-direction: row-reverse; /* Colocar el div al lado derecho */
 border: 2px solid black
  
}


.wallet div {
  min-width: auto;
  min-height: auto;
  padding: 0.1rem;
  background-color: darkgoldenrod;
  border-radius: 20%;
 justify-content: center;

}

.wallet svg {
  width: 1.6rem;
  height: 1.8rem;
  /*fill: white;*/
}


.wallet img{
  z-index: 1;
    width: 2.5em;
    height: 2.5em;
}
/* Estilo para la tabla */
table {
  border-collapse: separate;
  border-spacing: 0.5em;
  border-radius: 1em;
  overflow: hidden; /* Para que los bordes ovalados no se salgan */
  background-color: transparent;
}

td {
  background-color: #222;

  border-radius: 1em;
}




.walletBalanceTabla{
display: grid;
}

/* Estilo para el span */
.wallet span {
  display: table-cell;

  background-color: #222;
  color: gold;
  width: 100%;
  /*padding: 2px 4px;*/
  border-radius: 1px;
  font-size: 0.8em;
 
}

/* Style for the button */
.wallet button {
   display:inline-block;
   background-color:#444444cc; /* Dark button background for contrast */
   color:#ffd700; /* Bright golden text color */
   padding:.5em .75em; 
   border-radius:.5rem; 
   cursor:pointer;
   transition:.3s ease-in-out; /* Smooth transition for hover effects */
}

.wallet button:hover {
   background-color:#ffd700 ; /* Lighter gold on hover */  
}


.botton-wallet {
  position: absolute;
  width: 200%; /* Ajusta el tamaño de las imágenes */
  height: 200%; /* Ajusta el tamaño de las imágenes */
  border: 0.1em solid gold; /* Borde en modo light */

border-radius: 50% 10% 50% 10%; 

}

.botton-wallet:hover {

  border: 0.2em solid white; /* Borde en modo light */
   border-radius: 50% 50% 80% 80%;
 /*(Esquina inferior redondeada) 10ptos*/ 
   /*filter: brightness(2) hue-rotate(225deg);; /* Aumenta la luminosidad en un 20% */
}


.button-wallets{
  display: contents;
}






