/* RegistroNotificacion.css */
.registro-notificacion {
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
}

.registro-notificacion.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.registro-notificacion.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}
