/* ModalBoletoIndividual.css */

.ModalBoletoInv {
  display: flex; /* Usar flexbox para centrar el modal */
  position: fixed; /* Posicionamiento fijo para cubrir toda la pantalla */
  top: 0; /* Inicia desde la parte superior */
  left: 0; /* Inicia desde la parte izquierda */
  right: 0; /* Se extiende hasta la derecha */
  bottom: 0; /* Se extiende hasta la parte inferior */
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semi-transparente */
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
  z-index: 1000; /* Asegúrate de que el modal esté por encima de otros elementos */
}

.ModalBoletoInv-content {
  background-color: #fff; /* Fondo blanco para el contenido del modal */
  border-radius: 5px; /* Bordes redondeados */
  padding: 20px; /* Espaciado interno */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para dar profundidad */
  width: 300px; /* Ancho del modal, ajustable según sea necesario */
  text-align: left; /* Alinear texto a la izquierda */
}


.ModalBoletoInv-content p {
 color: black;
}

.ModalBoletoInv-content ul {
 color: black;
}

.ModalBoletoInv-content h4 {
 color: black;
}


.ModalBoletoInv-content h3 {
 color: black;
}

.ModalBoletoInv-content li {
 color: black;
}
.ModalBoletoInv-button {
  background-color: #007bff; /* Color del botón */
  color: white; /* Color del texto del botón */
  border: none; /* Sin borde */
  padding: 10px 15px; /* Espaciado interno */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer; /* Cambiar a cursor de mano */
  transition: background-color 0.3s; /* Transición suave al cambiar color */
}

.ModalBoletoInv-button:hover {
  background-color: #0056b3; /* Color al pasar el mouse */
}