.torneo-premios {
  background-color: #333; /* Fondo oscuro para contraste */
  border-radius: 10px;
  padding: 15px;
  margin-top: 20px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.3);
}

.torneo-premios button {
  background-color: #4CAF50; /* Color verde para el botón */
  color: white; /* Texto blanco */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.torneo-premios button:hover {
  background-color: #45a049; /* Verde más oscuro al pasar el mouse */
}

.premios-info {
  margin-top: 15px;
}

.premios-info h4 {
  color: white; /* Títulos en blanco */
  border-bottom: 2px solid #4CAF50; /* Línea verde debajo del título */
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.premios-info ul {
  list-style-type: none; /* Sin viñetas */
  padding: 0;
}

.premios-info li {
  background-color: #444; /* Fondo gris oscuro para los elementos de la lista */
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
}

.premios-info li:nth-child(1) {
  background-color: #B8860B; /* Dorado para primer lugar */
}

.premios-info li:nth-child(2) {
  background-color: #C0C0C0; /* Plateado para segundo lugar */
}

.premios-info li:nth-child(3) {
  background-color: #CD7F32; /* Bronce para tercer lugar */
}

.premios-info li span {
  margin-right: 10px;
  font-weight: bold;
}

.premios-info li p {
    color: white; /* Texto en blanco dentro de los premios */
}

/* Estilos responsivos */
@media (max-width: 768px) {
    .torneo-premios {
        padding: 10px;
    }

    .torneo-premios button {
        width: 100%; /* Botón ocupa todo el ancho en pantallas pequeñas */
    }

    .premios-info li {
        flex-direction: column; /* Cambiar a columna en pantallas pequeñas */
        align-items: flex-start; /* Alinear a la izquierda */
    }
}
