/* SearchInput.css */
.search-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%; /* Para que ocupe todo el espacio disponible */
  max-width: 400px; /* Limitar el tamaño máximo del input */
}

.search-input {
  width: 7em;
  padding: 10px 10px 10px 35px; /* Añadimos espacio en la izquierda para el icono */
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  outline: none;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #1E3A8A; /* Cambia al color principal al hacer focus */
}

.search-icon {
  position: absolute;
  left: 10px; /* Posiciona la lupa a la izquierda del input */
  color: #6B7280; /* Gris elegante */
  cursor: pointer;
  font-size: 18px; /* Aumenta el tamaño para hacerlo más visible */
  transition: color 0.3s ease;
}

.search-icon:hover {
  color: #1E3A8A; /* Cambia a color principal al hacer hover */
}
