.light {
	background:  #167CBB; }

/*.containermain{
     background: linear-gradient(135deg, #167CBB , #D54B4B, #423F3F );



	margin-left: 10vh;
	margin-right: 10vh;
	}
*/
.dark{
	background: #423F3F;
	background-image: url(https://www.blockhorseracing.com/image/fondos/fondohaBack.png);

}


.containermain {
  background: linear-gradient(135deg, rgba(22, 124, 187, 0.3), rgba(213, 75, 75, 0.3), rgba(66, 63, 63, 0.3));
  background-image: url(https://www.blockhorseracing.com/image/backgroundballos/track2.jpg);
  background-size: cover;  /* Añade esta propiedad para que la imagen se adapte y cubra el fondo */
  background-position: center;  /* Centra la imagen en el fondo */
  margin-left: 10vh;
  margin-right: 10vh;
}



/* Estilos generales */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
}

 #my-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #f5f5f5; /* Ajusta el fondo según el diseño de tu página */
}




#my-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  background-color: #f5f5f5; /* Ajusta el fondo según el diseño de tu página */
}






/*Colores nuevos de la pagina 515573  #daae34   gold*/
/*Colores viejos  background: linear-gradient(#854D0D, #12897A);*/
@media screen and (max-width: 768px) {


/*.containermain{
	 background: linear-gradient(135deg, #167CBB , #D54B4B, #423F3F);
      margin-left: 1vh;
	margin-right: 1vh;
	}*/


	.containermain {
  background: linear-gradient(135deg, #167CBBB2, #D54B4BB2, #423F3FB2);
  margin-left: 1vh;
  margin-right: 1vh;
}


.containermain {
  background-image: url(https://www.blockhorseracing.com/image/fondos/fondoh.png); /* Reemplaza 'ruta/de/tu/imagen.jpg' con la ubicación de tu imagen */
  background-repeat: repeat; /* Esto hace que la imagen se repita tanto en el eje X como en el eje Y */
  margin-left: 1vh;
  margin-right: 1vh;
}
}