/* Estilo general del contenedor del módulo de boletos */
.SponsorModule {

display: flex;
max-width: 100%;}

.SponsorModule .app{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.TicketModule {
  display: inline-block;
  font-family: 'Arial', sans-serif;
  padding: 1em;
  background-color: rgba(249, 249, 249, 0.8); /* Fondo blanco con 80% de opacidad */
  max-width: 90%;
  width: max-content;
  margin: 0 auto;
  margin: 1em;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


/* Títulos de sección */
.TicketModule h2 {
  color: #1E3A8A; /* Azul profundo */
  font-size: 1.2rem;
  margin-bottom: 10px;
  border-bottom: 2px solid #6B7280;
  padding-bottom: 5px;
}

/* Estilo de las listas de boletos */
.TicketModule ul {
  display: contents;
  list-style-type: none;
  padding-left: 0;
}

.TicketModule li {
  background-color: #10B981; /* Verde fresco */
  color: white;
  padding: 10px 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.TicketModule li:hover {
  background-color: #065f46; /* Verde más oscuro al pasar el ratón */
}

/* Estilo para el mensaje de no boletos disponibles */
.TicketModule p {
  color: #6B7280; /* Gris elegante */
  font-size: 1rem;
}

/* Estilo para la entrada de texto */
.TicketModule input[type="text"] {
  width: 80%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 2px solid #1E3A8A; /* Borde azul profundo */
  border-radius: 5px;
  font-size: 1rem;
}

/* Botón de búsqueda */
.TicketModule button {
  /*background-color: #FBBF24; /* Amarillo vibrante */
  color: #fff;
  border: none;
  padding: 0.6em 1.5em;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.TicketModule button:hover {
  /*background-color: #f59e0b;  Amarillo más oscuro al pasar el ratón */
}


 svg{
width: 1.2rem;
height: 1.2rem;
fill:white;

}



.SponsorModule {
  display: flex;
  gap:0.5em ;
  flex-wrap: wrap;
}


.horse-item {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.horse-header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
}

.horse-attributes {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.horse-attributes span {
  margin: 0 10px;
}



/* Estilos de los botones para alternar entre divs */
.toggle-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.toggle-buttons button {
  background-color: #1E3A8A; /* Azul profundo, según tu paleta */
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.toggle-buttons button:hover {
  background-color: #10B981; /* Verde de tu paleta para el hover */
}

.toggle-buttons button.active {
  background-color: #FBBF24; /* Amarillo vibrante para el botón activo */
}

/* Ocultar los divs no activos */
.hidden {
  display: none;
}

/* Otros estilos que necesites ajustar */
.TicketModule, .SponsorModule {
  border: 1px solid #6B7280; /* Gris elegante */
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
}


/* Estilo del modal (ModalBoletoIndividual) */
.ModalBoletoInv {
  position: fixed;
  top: 0;
  left: 0;
  width: 80%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: right;
  align-items: center;
}

.ModalBoletoInv-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  max-width: 60%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.ModalBoletoInv h3 {
  color: #1E3A8A;
  font-size: 1.4rem;
  margin-bottom: 15px;
}

.ModalBoletoInv p {
  color: #6B7280;
  margin-bottom: 10px;
  font-size: 1rem;
}

.ModalBoletoInv-button {
  background-color: #10B981; /* Verde fresco */
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.ModalBoletoInv-button:hover {
  background-color: #065f46; /* Verde más oscuro */
}

