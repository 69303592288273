.wrapp_especial {
  display: inline-table;
  align-items:  ; /* Alinea los elementos hacia arriba */
  max-width: 30%;
  max-height: 100px;
}



.wrapp_especial .cofres-disponibles-especiales{
  display: inline-flex;
  align-items: flex-start; /* Alinea los elementos hacia arriba */
}

.wrapp_especial .cofres-disponibles-especiales-qty{
   display: flex;
  flex-direction: column;
  margin-left:-5%;
  text-align: center;
}

.especial-chest {
  background: gold;
  border-radius: 5px;
  color: black;
  padding: 5px;
  display: inline-block;
  float: right;
  text-decoration: none; /* Para quitar la subrayado del enlace */
}

.especial-chest:hover {
  background: gold; /* Cambiar el color de fondo al pasar el mouse */
}

.cofres-disponibles-especiales {
  display: absolute;
  align-items: flex-start;
  /*margin: 10px;*/
}

.cofres-disponibles-especiales h5 {
  font-size: 1rem;
  align-items: flex-start;
  
}

.cofres-disponibles-especiales-title {
  display: flex;
  align-items: flex-start;
  /*margin: 10px;*/
}

.cofres-disponibles-especiales-title h5 {
  font-size: 0.9rem;
  align-items: stretch;
  
}
.cofres-disponibles-especiales img {
  width: 30%;
  height: 30%;
}

.cofres-disponibles-especiales-qty {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  text-align: center;
}

.cofres-disponibles-especiales h2 {
  font-size: 1rem;
}

.cofres-disponibles-especiales button {
  position: relative;
  align-self: end;
  background: goldenrod;
  border: none;
  border-radius: 0.3em;
  padding: 0.1em 0.3em;
  cursor: pointer;

}

.cofres-disponibles-especiales button:hover {
  background: black;
}

