.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, sans-serif;
  font-size: 3rem;
  color: #fff;
  text-shadow: 2px 2px 4px #000;
}

.countdown-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0.7rem;
  background-color: #333;
  border-radius: 1rem;
  box-shadow: 2px 2px 4px #000;
  padding: 1rem;
}

.countdown-value {
  font-weight: bold;
  font-size: 0.8rem;
}

.countdown-label {
  font-size: 0.7rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-top: 0.5rem;
}

/* Estilos responsivos para pantallas pequeñas */
@media (max-width: 600px) {
  .countdown {
    font-size: 2rem; /* Reduzca el tamaño de fuente en pantallas pequeñas */
  }

  .countdown-item {
    margin: 0 0.4rem; /* Reduzca el margen en pantallas pequeñas */
    padding: 0.7rem; /* Reduzca el padding en pantallas pequeñas */
  }

  .countdown-value {
    font-size: 0.7rem; /* Reduzca el tamaño de fuente en pantallas pequeñas */
  }

  .countdown-label {
    font-size: 0.6rem; /* Reduzca el tamaño de fuente en pantallas pequeñas */
    margin-top: 0.3rem; /* Reduzca el margen superior en pantallas pequeñas */
  }
}
