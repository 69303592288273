



.caballo-info-imagen {
  display: grid;
  grid-template-areas: 
    "status . tipo"
    ". image ."
    "footer footer footer";
  grid-template-columns: 1fr auto 1fr; /* Columnas para posicionar elementos */
  grid-template-rows: auto 1fr auto; /* Filas para los textos y el trapecio */
  align-items: start; /* Centra verticalmente */
  justify-items: center; /* Centra horizontalmente */
  position: relative;
  /*padding: 0.5em;  Quita el padding para que la imagen ocupe todo el espacio */
  background-color: transparent; /* Cambia a transparente si es necesario */
    width: content; /* Limita el ancho al contenido */
  height: content; /* Limita la altura al contenido */
  position: 0;
}

.image {
  grid-area: image; /* Asigna toda el área media a la imagen */
  position: absolute; /* Coloca la imagen de forma absoluta dentro del contenedor */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 10%; /* Asegura que la imagen ocupe todo el ancho del contenedor */
  height: 10%; /* Asegura que la imagen ocupe todo el alto del contenedor */
  object-fit: cover; /* Ajusta la imagen para cubrir todo el espacio sin distorsión */
  z-index: -1; /* Coloca la imagen detrás de otros elementos del grid */
}

/**/



.HorseImage {
  grid-area: image;
  max-width: 100%; /* Ajusta según el tamaño deseado */
  object-fit: cover; /* Mantiene la proporción de la imagen */
}




