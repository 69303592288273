/* Acumulados.css */

/* Contenedor principal con borde animado */
.section.acumulado-pote {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid transparent;
  background-clip: padding-box;
  position: relative;
  padding: 0.2rem;
  width: 90%;
  overflow: hidden;
  border-radius: 10px;
}

.section.acumulado-pote::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  background: linear-gradient(45deg, #1E3A8A, #3B82F6, #1E3A8A);

  border-radius: inherit;
  z-index: -1;
  animation: neonBorder 2s linear infinite;
}

.acumulado-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #fbbf24;
top: 0px;
right: 0px;}

.acumulado-list {
  display: flex;
  flex-wrap: wrap; /* Permite que las tarjetas hagan wrap si es necesario */
  gap: 12px; /* Espacio entre las tarjetas */
  justify-content: center; /* Centra las tarjetas */
  width: 100%;
}

.acumulado-item {
  background-color: #334155;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  white-space: nowrap; /* Evita que el contenido de cada tarjeta se divida en varias líneas */
  min-width: fit-content; /* La tarjeta ocupará solo el espacio necesario */
}

.coin-logo {
  width: 24px;
  height: 24px;
}

.acumulado-value {
  font-size: 1.2em;
  font-weight: bold;
  color: #fbbf24;
}
