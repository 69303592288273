/* Mantenimiento.css */
.pancarta-container {
  font-size: 2em;
  padding: 1em;
  border: 1px solid blue;
  background: linear-gradient(90deg, transparent, #00f, transparent);
  background-size: 200% 100%;
  color: #00f;
  text-align: center;
  animation: neonText 2s linear infinite;
}

.pancarta-container h1{
  color: goldenrod;
  font-size: 1em;
}
 
@keyframes neonText {
  0%,
  100% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
}

/* Puedes ajustar el estilo según tus necesidades */
.pancarta-text {
  margin: 0;
}
