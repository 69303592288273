.dark {
 text-align: center;
 background-color: #423F3F;
 text-decoration-color: white;

}

.dark  main{
 text-align: center;
 background-color: #014874;
 text-decoration-color: white;

}


.dark h1{
  color: white;
}

.dark h2{
  color: white;
}
.dark h3{
  color: white;
}
.dark h4{
  color: white;
}
.dark h5{
  color: white;
}
.dark h6{
  color: white;
}
.dark p{
  color: white;
}
.dark li{
  color: white;
}
.dark a{
  color: white;
}













.light  {
  text-align: center;
 background-color: #C4C4C4;

}



.light main{
  background-color: #167CBB;
}




.light nav {
 text-align: center;
 background-color: #167CBB;
 text-decoration-color: white;

}


.light h1{
  color: #167CBB;
}

.light h2{
  color: white;
}
.light h3{
  color: white;
}
.light h4{
  color: white;
}
.light h5{
  color: white;
}
.light h6{
  color: white;
}
.light p{
  color: white;
}
.light li{
  color: white;
}



