.modal-info-b{
  position: fixed;
  z-index: 109;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: none;
  justify-content: center;
  align-items: center;
}


.modal-container-info-b {
  position: relative;
  padding: 1rem;
  width: min-content;
  min-height: 200px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: #000;
}


.modal-info-b-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.modal-info-b.is-open {
    display: flex;
    justify-content: center;  /* Centra horizontalmente */
    align-items: center;      /* Centra verticalmente */
    max-width: ;
    height: 100vh;            /* Asegúrate de que el contenedor ocupe toda la altura de la ventana */
    position: fixed;          /* Fija el contenedor para que se superponga al contenido */
    top: 0;                   /* Posiciona en la parte superior de la ventana */
    left: 0;                  /* Posiciona en la parte izquierda de la ventana */
    right: 0;                 /* Asegura que ocupe toda la anchura */
    bottom: 0;                /* Asegura que ocupe toda la altura */
    background-color: rgba(0, 0, 0, 0.5);  /* Fondo semitransparente para superposición */
    z-index: 5;            /* Asegura que esté encima de otros elementos */
}





/* Ajustes para el diseño responsivo */
@media (min-width: 576px) {

  .modal-container-info-b {
  width: max-content;
  min-height: 200px;
  max-height: 90%;
  overflow-y: auto;
  background-color: #000;
}



}

@media (min-width: 768px) {
.modal-container-info-b {
  width: max-content;
  min-height: 200px;
  max-height: 80%;
  overflow-y: auto;
  background-color: #000;
}

}

@media (min-width: 992px) {

.modal-container-info-b {
  width: max-content;
  min-height: 200px;
  max-height: 70%;
  overflow-y: auto;
  background-color: #000;
}

}

@media (min-width: 1200px) {
.modal-container-info-b {
  width: max-content;
  min-height: 200px;
  max-height: 70%;
  overflow-y: auto;
  background-color: #000;
}
}


