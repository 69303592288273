.fotter-light {
/*background: #C4C4C4;*/
 background: linear-gradient(135deg, #167CBB, #D54B4B, #167CBB);

}


.fotter-dark {
/*background:#423F3F;*/
 background: linear-gradient(135deg, #423F3F, #D54B4B, #423F3F);

}

.container-footer {
margin-left: 10vh;
margin-right: 10vh;
/*background:linear-gradient(#12897A,#4e3404);*/
}

.container-footer img {
  margin-left: 3vh;
  margin-right: 3vh;
  max-width: 15%;
}


/*.logo {
  width: 100px;
  height: 100px;
  margin: 10px;
}
*/


.logo {
    width: 100px;
  height: 100px;
  margin: 10px;

  transition: transform 0.3s ease;
}

.logo:hover {
transform: scale(1.5);
  transition: transform 0.3s ease-in-out;}



@media screen and (max-width: 768px) {

.container-footer {
margin-left: 1vh;
margin-right: 1vh;

}

}













