/* carta.css */
.carta {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  max-width: 600px;
  margin: 0 auto; /* Centrar la tarjeta horizontalmente */
  background: rgba(0, 0, 0, 0.6); /* Fondo semi-transparente */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Añadir sombra */
}

.carta-content {
  display: flex;
  flex-direction: row; /* Alinear los elementos en una fila */
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap; /* Para que los elementos se ajusten en pantallas pequeñas */
}

.left-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px; /* Espacio entre las columnas */
}

.right-column {
  flex: 1;
}

.carta-image {
  width: 150px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 16px; /* Espacio debajo de la imagen */
}

.purchased-list {
  text-align: left;
}

.purchased-list h4 {
  margin-bottom: 8px;
}

.purchased-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.purchased-list li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.purchased-image {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin-right: 8px;
}

@media (max-width: 768px) {
  .carta-content {
    flex-direction: column; /* Cambiar a columna en pantallas pequeñas */
    align-items: center;
  }
  
  .left-column {
    margin-right: 0;
    margin-bottom: 16px; /* Añadir espacio debajo de la columna izquierda */
  }
  
  .right-column {
    text-align: center;
  }
}
