/* Modal overlay */
.modalboleto-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Fondo oscuro con transparencia */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Asegura que el modal esté por encima de otros elementos */
}




.modalboleto-content {
  position: relative; /* Necesario para posicionar pseudo-elementos */
  background: #1E3A8A; /* Azul profundo del tema principal */
  padding: 1rem 3rem;
  margin: 10px;
  border-radius: 5rem;
  width: 90%;
  min-width: 320px;
  max-width: 600px; /* Limita el ancho máximo */
  max-height: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Sombra más definida */
  overflow: visible; /* Permite que la imagen sobresalga */
  color: #F3F4F6; /* Texto blanco-grisáceo para contraste */
  z-index: 10; /* Asegura que el modal esté en un nivel alto */
}

.modalboleto-content::before {
  content: "";
  position: absolute;
  top: 10%;
  right: -30%; /* Mueve la imagen hacia la derecha */
  width: 120%; /* Ajusta el ancho para cubrir más allá del modal */
  height: 80%;
  background-image: url(http://localhost:3000/image/fondos/ballorace.png);
  background-size: contain; /* Ajusta el tamaño de la imagen */
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  background-position: right; /* Posiciona la imagen en la parte derecha */
  opacity: 0.8; /* Ajusta la transparencia si es necesario */
  z-index: -1; /* Coloca la imagen debajo del contenido del modal */
  pointer-events: none; /* Asegura que no afecte la interacción del modal */
}






/* Race selection section */
.race-selection {
  display: flex;
  align-items: center; /* Alinea el título con los checkboxes */
  margin-bottom: -1.7rem;
  /*padding-bottom: 8px;*/
  border-bottom: 1px solid #374151; /* Línea divisoria más oscura */
  padding: 1em;
}

/* Race header */
.race-selection h3 {
  font-size: 1.1rem; /* Ajuste del tamaño de fuente para subtítulos */
  margin-right: 1vw; /* Espacio entre el título y las opciones */
  color: #FBBF24; /* Acento amarillo vibrante */
  white-space: nowrap; /* Evita que el texto se rompa en varias líneas */
}

.modalboleto-content h2 {
  font-size: 1.1rem; /* Ajuste del tamaño de fuente para subtítulos */
  margin-right: 12px; /* Espacio entre el título y las opciones */
  color: #FBBF24; /* Acento amarillo vibrante */
  white-space: nowrap; /* Evita que el texto se rompa en varias líneas */
}


/* Horse options layout */
.horse-options {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0.5rem; /* Espacio entre elementos */
  flex-wrap: wrap; /* Permite que los checkboxes se ajusten si hay muchas opciones */
}

/* Each option styling */
.horse-options li {
  display: contents;
  align-items: center;
  font-size: 0.85rem; /* Fuente más pequeña para reducir espacio */
}

/* Style for checkboxes */
.horse-options input[type="checkbox"] {
  margin-right: 4px; /* Espacio entre el checkbox y el número */
  transform: scale(1.2); /* Aumenta ligeramente el tamaño del checkbox */
  accent-color: #FBBF24; /* Color de acento para los checkboxes */
}

/* Buttons */
.buy-button,
.close-button {
  margin-top: 0.5rem;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background 0.3s, transform 0.2s;
}

.buy-button {
  background: #10B981; /* Verde fresco para indicar acción positiva */
  color: white;
  margin-right: 8px; /* Espacio entre botones */
}

.buy-button:hover {
  background: #059669; /* Cambio de color al pasar el ratón */
  transform: translateY(-2px); /* Efecto de elevación al pasar el ratón */
}

.close-button {
  background: #6B7280; /* Gris medio para el botón de cerrar */
  color: white;
}

.close-button:hover {
  background: #4B5563; /* Cambio de color al pasar el ratón */
  transform: translateY(-2px); /* Efecto de elevación al pasar el ratón */
}

/* Ajustes adicionales para un layout más compacto */
.modalboleto-content p {
  font-size: 0.9rem;
  margin: 0.5rem 0; /* Reducir márgenes para un diseño más compacto */
}


/* Modal header */
.modal-header {
  display: flex;
 justify-content: center; /* Centra los elementos en el contenedor */
  align-items: center; /* Centra verticalmente los elementos */
  margin-bottom: 0.1rem; /* Espacio debajo del encabezado */
  padding: 0; /* Elimina el padding para ajustar el espacio */
}

/* Title styling */
.modal-header h2 {
  margin: 0; /* Elimina el margen para mantener el título alineado */
  font-size: 1rem; /* Ajuste del tamaño de fuente */
  margin-right: 8px; /* Espacio entre el título y el botón */
}


/* Rules link */

.rules-button {
  background: #FBBF24; /* Color de fondo amarillo del botón */
  color: #1E3A8A !important; /* Color del texto azul con importancia */
  border: none;
  border-radius: 5px;
  padding: 6px 8px; /* Ajusta el padding para que el botón sea más compacto */
  text-decoration: none; /* Elimina el subrayado */
  cursor: pointer;
  font-size: 0.85rem; /* Tamaño del texto del botón */
  transition: background 0.3s, transform 0.2s;
}

/* Hover state for the rules button */
.rules-button:hover {
  background: #F59E0B; /* Cambio de color a un amarillo más oscuro al pasar el ratón */
  color: #1E3A8A !important; /* Mantiene el color del texto azul al pasar el ratón */
  transform: translateY(-1px); /* Efecto de elevación más sutil al pasar el ratón */
}



/* ModalSelectHorses.css */

/* Estilo general para el icono de información */
.info-icon {
  margin-left: 0.5rem; /* Espacio a la izquierda del texto de la carrera */
  font-size: 1em; /* Tamaño del icono, ajustable según diseño */
  color: #ffffff; /* Color del icono */
  background-color:  #F59E0B; /* Fondo del icono, ajustable según esquema de colores */
  border-radius: 100%; /* Bordes redondeados, hace que sea circular */
  padding: 3px; /* Espacio interior alrededor del icono */
  display: inline-flex; /* Alinea el contenido en línea y permite flexbox */
  align-items: center; /* Centra el icono verticalmente */
  justify-content: center; /* Centra el icono horizontalmente */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra para el efecto 3D */
  transition: background-color 0.3s, transform 0.3s; /* Transiciones para efectos */
  text-decoration: none; /* Quita subrayado */
}

/* Efecto hover para el icono */
.info-icon:hover {
  background-color: #0056b3; /* Color del fondo al pasar el cursor, ajustable */
  transform: scale(1.1); /* Aumenta el tamaño del icono al pasar el cursor */
}

@media (max-width: 500px) {


  .modalboleto-content {
  position: relative; /* Necesario para posicionar pseudo-elementos */
  background: #1E3A8A; /* Azul profundo del tema principal */
  padding: 1rem ;
  margin: 5px;
  border-radius: 5rem;
  width: 90%;
  min-width: 320px;
  max-width: 600px; /* Limita el ancho máximo */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Sombra más definida */
  overflow: visible; /* Permite que la imagen sobresalga */
  color: #F3F4F6; /* Texto blanco-grisáceo para contraste */
  z-index: 10; /* Asegura que el modal esté en un nivel alto */
}

.modalboleto-content::before {
  content: "";
  position: absolute;
  top: 0;
  right: -30%; /* Mueve la imagen hacia la derecha */
  width: 120%; /* Ajusta el ancho para cubrir más allá del modal */
  height: 100%;
  background-image: url(http://localhost:3000/image/fondos/ballorace.png);
  background-size: contain; /* Ajusta el tamaño de la imagen */
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  background-position: right; /* Posiciona la imagen en la parte derecha */
  opacity: 0.8; /* Ajusta la transparencia si es necesario */
  z-index: -1; /* Coloca la imagen debajo del contenido del modal */
  pointer-events: none; /* Asegura que no afecte la interacción del modal */
}
 /* .modalboleto-content {
    padding: 1rem;  Reducir el padding en pantallas pequeñas 

    margin: 5px; /* Reducir el margen en pantallas pequeñas 
  }
*/
  .modal-header {
    flex-direction: column; /* Coloca el título y el botón en columna en lugar de fila */
    align-items:center; /* Alinea los elementos al inicio */
  }

  .modal-header h2 {
    margin-right: 0; /* Elimina el margen derecho en pantallas pequeñas */
    margin-bottom: 8px; /* Añade un margen inferior para separación */
  }

  .rules-button {
    font-size: 0.75rem; /* Reduce el tamaño del texto en pantallas pequeñas */
    padding: 4px 6px; /* Ajusta el padding en pantallas pequeñas */
  }

  .race-selection h3 {
    font-size: 1rem; /* Reduce el tamaño de fuente en pantallas pequeñas */
  }

  .horse-options {
    flex-direction: row; /* Cambia la dirección de los elementos a columna en pantallas pequeñas */
  }

  .horse-options li {
    font-size: 0.8rem; /* Reduce el tamaño de la fuente en pantallas pequeñas */
  }
}










/* Media Query para pantallas más pequeñas */
@media (max-width: 768px) {
  .modalboleto-content::before {
  content: "";
  position: absolute;
  right: -20%; /* Mueve la imagen hacia la derecha */
  width: 120%; /* Ajusta el ancho para cubrir más allá del modal */
  height: 100%;
  background-image: url(http://localhost:3000/image/fondos/ballorace.png);
  background-size: 50% 50%; /* Ajusta el tamaño de la imagen */
  opacity: 1; /* Ajusta la transparencia si es necesario */
  }

.modalboleto-content {
  padding: 1rem 5rem;
  width: 90%;
  min-width: 320px;
  max-width: 520px; /* Limita el ancho máximo */




}



}




@media (max-width: 480px) {
  
  .modalboleto-content::before {
  content: "";
  position: absolute;
  right: 10%; /* Mueve la imagen hacia la derecha */
  width: 120%; /* Ajusta el ancho para cubrir más allá del modal */
  height: 20%;
  background-image: url(http://localhost:3000/image/fondos/ballorace.png);
  background-size: 50% 50%; /* Ajusta el tamaño de la imagen */
  opacity: 1; /* Ajusta la transparencia si es necesario */
  }

.modalboleto-content {
  padding: 1rem ;
  width: 90%;
  min-width: 300px;
  max-width: 400px; /* Limita el ancho máximo */




}

 
.modalboleto-content::before {
  content: "";
  position: absolute;
  top: 30%;
  right: -10%; /* Mueve la imagen hacia la derecha */
  width: 120%; /* Ajusta el ancho para cubrir más allá del modal */
  height: 100%;
  background-image: url(http://localhost:3000/image/fondos/ballorace.png);
  background-size: 50% 50%; /* Ajusta el tamaño de la imagen */
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  background-position: right; /* Posiciona la imagen en la parte derecha */
  opacity: 0.8; /* Ajusta la transparencia si es necesario */
  z-index: -1; /* Coloca la imagen debajo del contenido del modal */
  pointer-events: none; /* Asegura que no afecte la interacción del modal */
}

  .modal-header {
    flex-direction: column; /* Coloca el título y el botón en columna en lugar de fila */
    align-items:center; /* Alinea los elementos al inicio */
  }

  .modal-header h2 {
    margin-right: 0; /* Elimina el margen derecho en pantallas pequeñas */
    margin-bottom: 5px; /* Añade un margen inferior para separación */
  }

  .rules-button {
    font-size: 0.75rem; /* Reduce el tamaño del texto en pantallas pequeñas */
    padding: 4px 6px; /* Ajusta el padding en pantallas pequeñas */
  }

  .race-selection h3 {
    font-size: 0.7rem; /* Reduce el tamaño de fuente en pantallas pequeñas */
  }

  .horse-options li {
    font-size: 0.65rem; /* Reduce el tamaño de la fuente en pantallas pequeñas */
  }



}




