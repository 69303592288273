.CardCofresMagicos {
  position: relative;
  max-width: 220px;
  height: auto;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.2),
  inset -4px -4px 15px rgba(255, 255, 255, 0.1),
  4px 4px 15px rgba(0, 0, 0, 0.3), -4px -4px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 8px;
  transition: 0.5s;
}


  .CardCrofresMagicos .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}


  .CardCrofresMagicos .box:hover {
  transform: translateY(-50px);
}

  .CardCrofresMagicos .box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

  .CardCrofresMagicos .box .content  {
  padding: 20px;
  text-align: center;
}



  .CardCrofresMagicos .box .content .caja {
  position: relative;
  border-radius: 8px;
  border: 3px solid #12897A;
  width: 100%;
  background-size: cover;
}


 .cajaCofresMagicos {
  position: relative;
  border-radius: 8px;
  border: 3px solid #12897A;
  width: 100%;
  background-size: cover;
}



.cajaCofresMagicos img {
  width: 100%;
 border: 2px solid transparent;
 top: 2px;
 
 }





/* Estilos base para los botones */
.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
  width: 70%;
}

.button-container button {
  flex: none;
  width: 23%;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  box-sizing: border-box;
  text-align: center;
}

.button-container button:hover {
  background-color: #0056b3;
}

/* Media query para pantallas más pequeñas */
@media (max-width: 768px) {
  .button-container {
    width: 100%;
    gap:1%; /* Ocupar todo el ancho disponible */
  }

  .button-container button {
    flex: none; /* Deshabilitar el crecimiento */
    width: 40%; /* Ocupar todo el ancho del contenedor */
    margin-bottom: 1px; /* Espacio entre botones en pantallas pequeñas */
  }
}
