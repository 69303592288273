
  .last-races-table {
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*margin-bottom: 20px;  Espacio entre las tablas */
  }

  .last-races-table table {
    border-collapse: collapse;
    /*width: 45%;  Ajusta el ancho de la tabla según tus necesidades */
    /*margin-right: 0px;  Espacio entre las tablas */
  }

  .last-races-table th,
  .last-races-table td {
    border: none;
    padding: 0.05em;
    /*text-align: left;*/
    font-size: 0.75em; /* Tamaño de la letra */
  }

  .last-races-table a {
    text-decoration: none;
    color: #23c186; /* Color del enlace */
  }
