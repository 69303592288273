/* Modal overlay */
.modalPremioJ-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Fondo oscuro con más opacidad */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(8px); /* Efecto de desenfoque en el fondo */
}

/* Modal content */
.modalPremioJ-content {
  position: relative;
  background: linear-gradient(145deg, #0d0f1a, #1E3A8A); /* Gradiente oscuro a azul profundo */
  padding: 2rem 3rem;
  border-radius: 1rem;
  width: 90%;
  min-width: 320px;
  max-width: 80%;
  max-height: 90%;
  box-shadow: 0 0 15px rgba(255, 0, 150, 0.5), 0 0 25px rgba(0, 255, 255, 0.5); /* Sombras neon */
  color: #E0E7FF; /* Texto blanco con tono azulado */
  text-align: center;
  overflow-y: auto;
}


/* Animación de aparición */
.modalPremioJ-content {
  opacity: 0;
  transform: scale(0.9);
  animation: fadeInScale 0.4s forwards;
}

@keyframes fadeInScale {
  to {
    opacity: 1;
    transform: scale(1);
  }
}


/* Neon glow effect for header */
.modal-header h2 {
  font-size: 1.5rem;
  color:  #A5B4FC;
  text-shadow: 0 0 5px #FBBF24, 0 0 15px #FF3B3F; /* Efecto de resplandor */
}




.section {
  box-shadow: 0 0 5px rgba(30, 58, 138, 0.3);
}

/* Close button */
.close-button {
  background: #FF3B3F;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 0 5px rgba(255, 0, 150, 0.5), 0 0 15px rgba(255, 0, 150, 0.7); /* Efecto neon */
  box-shadow: 0 0 5px rgba(255, 105, 180, 0.5), 0 0 10px rgba(255, 105, 180, 0.7);
  margin-bottom: 1em;
  margin-right: 5em;
}

.close-button:hover {
  background: #FF69B4; /* Rosa neón */
  transform: translateY(-3px);
  box-shadow: 0 0 10px rgba(255, 0, 150, 0.8), 0 0 20px rgba(255, 0, 150, 1);
}

/* Input styling */
.search-input {
  background-color: #1B1F3B;
  color: #A5B4FC;
  border: 2px solid #4C51BF;
  padding: 0.7rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  outline: none;
  font-size: 0.8rem;
  box-shadow: 0 0 10px rgba(72, 61, 139, 0.8);
}

/* Section styling */
.section {
  background-color: rgba(30, 58, 138, 0.2); /* Fondo semi-transparente */
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 10px;
  color: #F3F4F6;
}

.section p {
  font-size: 1rem;
  color: #FBBF24;
  text-shadow: 0 0 4px black;
}


@keyframes neonBorder {
  0% {
    transform: translate(0px, 0px);
  }
  50% {
    transform: translate(2px, 2px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}


/* Hover para el botón de cerrar */
.close-button:hover {
  background: #FF69B4;
  transform: translateY(-2px);
  box-shadow: 0 0 8px rgba(255, 105, 180, 1);
}

/* Focus para el input */
.search-input:focus {
  border-color: #FF69B4;
  box-shadow: 0 0 12px rgba(72, 61, 139, 1);
}




.section.acumulado-pote,
.section.tickets,
.section.premios,
.section.carreras,
.section.estado {
  width: 90%;
  /*display: flex;*/
  border: 2px solid transparent;
  background-clip: padding-box;
  position: relative;
  padding: 1rem;
}

.section.acumulado-pote::before,
.section.tickets::before,
.section.premios::before,
.section.carreras::before,
.section.estado::before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
background: linear-gradient(45deg, #3A0CA3, #F72585, #4361EE);
  background: linear-gradient(45deg, #1E3A8A, #3B82F6, #1E3A8A);

  border-radius: inherit;
  z-index: -1;
  animation: neonBorder 2s linear infinite;
}


.rules-button-premiosJ {
  background:  #A5B4FC; /* Color de fondo amarillo del botón */
  color: #1E3A8A !important; /* Color del texto azul con importancia */
  border: none;
  border-radius: 5px;
  padding: 6px 8px; /* Ajusta el padding para que el botón sea más compacto */
  text-decoration: none; /* Elimina el subrayado */
  cursor: pointer;
  font-size: 0.85rem; /* Tamaño del texto del botón */
  transition: background 0.3s, transform 0.2s;
  color:  #FBBF24; /* Efecto de resplandor */

}

/* Hover state for the rules button */
.rules-button-premiosJ:hover {
  background: #A5B4FC; /* Cambio de color a un amarillo más oscuro al pasar el ratón */
  color: #1E3A8A !important; /* Mantiene el color del texto azul al pasar el ratón */
  transform: translateY(-1px); /* Efecto de elevación más sutil al pasar el ratón */
  text-shadow: 0 0 1px #FBBF24, 0 0 3px #FF3B3F; /* Efecto de resplandor */

}
