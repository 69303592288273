/* Estilos para el contenedor del loader */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* 100% del ancho disponible del contenedor padre */
  height: 3em; /* Máxima altura de 3em */
  position: relative;
  overflow: hidden; /* Para evitar desbordamientos */
}

/* Estilo para el fondo */
.loader-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; /* 100% del ancho disponible */
  height: 3em; /* Mantener la altura máxima en 3em */
  background: linear-gradient(135deg, #000, #fff); /* Fondo blanco y negro */
   background: linear-gradient(135deg, #f0f0f0, #a0a0a0); /* Gradiente blanco y gris */
 
  animation: waveEffect 2s linear infinite; /* Animación de ondas */
  border-radius: 5px; /* Bordes redondeados */
}

/* Animación para crear un efecto de ondas */
@keyframes waveEffect {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Estilo para el GIF del loader */
.loading-gif {
  width: auto; /* El ancho se adapta automáticamente */
  height: 3em; /* Mantener la altura máxima en 3em */ 
  animation: moveGif 5s linear infinite; /* Animación de movimiento del GIF */
  border-radius: 50%; /* Borde radial (redondeado) */
}

/* Animación para mover el GIF de izquierda a derecha */
@keyframes moveGif {
  0% {
    transform: translateX(-800%);
  }
  100% {
    transform: translateX(800%);
  }
}

/* Estilo para el texto "Loading..." */
.loading-text {
  position: absolute; /* Para superponer el texto encima del GIF */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2em; /* Tamaño del texto ajustado */
  font-weight: bold;
  color: #39ff14; /* Verde neón */
  text-shadow: 0 0 10px #39ff14, 0 0 20px #39ff14, 0 0 30px #39ff14;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .loading-text {
    font-size: 1em;
  }
}
