/* Estilos para pantallas pequeñas, como celulares */
@media (max-width: 600px) {
  .navbar-container {
    justify-content: center; /* Centra los botones en pantallas pequeñas */
  }

  .navbar-item {
    margin: 0 0.5rem; /* Agrega espacio horizontal entre los botones */
    max-width: 25%; /* Ajusta el tamaño máximo de los botones */
    border-radius: 10px; /* Radio de los botones redondeados */
  }

  .botton-navbar {
    min-width: 30%; /* Ajusta el tamaño mínimo de las imágenes */
    min-height: auto; /* Ajusta el tamaño mínimo de las imágenes */
    max-width: 100%; /* Ajusta el tamaño máximo de las imágenes */
    max-height: auto; /* Ajusta el tamaño máximo de las imágenes */
    border: 0.2em solid #ccc; /* Borde en modo light */

    transform: skew(20deg);
    filter: hue-rotate(5deg) brightness(0.8);
  }

  img.botton-navbar {
    max-width: 60%; /* Ajusta el tamaño máximo de las imágenes */
    max-height: auto; /* Ajusta el tamaño máximo de las imágenes */
  }

  .botton-navbar:hover {
    width: 100%; /* Ajusta el tamaño de las imágenes al hacer hover */
    height: auto; /* Ajusta el tamaño de las imágenes al hacer hover */
    border: 0.2em solid #FC1D0F; /* Borde en modo light */
    filter: brightness(1.8) hue-rotate(225deg); /* Aumenta la luminosidad en un 20% */
  }
}




.active {
  /* Estilos para el estado activo */
  background-color: #12897A;
  /* Otros estilos aquí */
  transform: skew(30deg);

  
}


/* Estilos para pantallas más grandes */
.navbar-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
}

.navbar-item {
  margin-right: -10%; /* Agrega un pequeño espacio entre los botones */
  border-radius: 0px; /* Radio de los botones redondeados */
  overflow: hidden; /* Oculta cualquier contenido fuera del radio */

  /* Estilos base */
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  display: inline-block;
}

.botton-navbar {
  min-width: 10%; /* Ajusta el tamaño de las imágenes */
  min-height: 10%; /* Ajusta el tamaño de las imágenes */
  max-width: 60%; /* Ajusta el tamaño de las imágenes */
  max-height: 60%; /* Ajusta el tamaño de las imágenes */
  border: 0.2em solid #ccc; /* Borde en modo light */

  transform: skew(20deg);
  filter: hue-rotate(5deg) brightness(0.8);
}

img.botton-navbar {
  max-width: auto; /* Ajusta el tamaño de las imágenes */
  max-height: auto; /* Ajusta el tamaño de las imágenes */
}

.botton-navbar:hover {
  width: 90%; /* Ajusta el tamaño de las imágenes */
  height: 90%; /* Ajusta el tamaño de las imágenes */
  border: 0.2em solid #FC1D0F; /* Borde en modo light */
  filter: brightness(1.8) hue-rotate(225deg); /* Aumenta la luminosidad en un 20% */
}

/* Estilos para el tema "light" */
.theme-light .navbar-item {
  background-color: #f5f5f5; /* Color de fondo en modo light */
  border: 0.2em solid #ccc; /* Borde en modo light */
  margin-right: 0.1em; /* Espacio entre botones en modo light */
}

.theme-light .navbar-item:hover {
  background-color: #ccc; /* Cambio de color en hover en modo light */
  transform: scale(1.05); /* Efecto de escala en hover en modo light */
}

/* Estilos para el tema "dark" */
.theme-dark .navbar-item {
  background-color: #333; /* Color de fondo en modo dark */
  border: 0.2em solid #555; /* Borde en modo dark */
  margin-right: 0.1em; /* Espacio entre botones en modo dark */
}

.theme-dark .navbar-item:hover {
  background-color: #555; /* Cambio de color en hover en modo dark */
  transform: scale(1.05); /* Efecto de escala en hover en modo dark */
}
