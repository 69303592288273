.alert {
  z-index: 1000;
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  /* Otros estilos personalizados */
}


.alert-success {
  z-index: 1000;
  background-color:  #23c186;
  color: #000000;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
  /* Otros estilos personalizados */
}