.advertising-screens {
  display: flex;
  justify-content: space-between;
  padding: 1em;

  padding-bottom: 1em;
}

.screen {
  width: 30%;

  /*transition: transform 0.3s;*/
  /*border: 0.5em solid #000;*/
  /*padding: 0.2em;*/
}

span{
  color: white;
}
.neon-border {
  border: 0.5em solid #000;
  /*padding: 20px;*/
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.8); /* Efecto de neón verde, puedes ajustar el color y el brillo según tu preferencia */
background: black;
 background-image: url('https://www.blockhorseracing.com/image/backgroundballos/track2.jpg');
  /* Otras propiedades opcionales para ajustar la apariencia de la imagen de fondo */
  background-size: cover; /* Para ajustar el tamaño de la imagen */
  background-position: center; /* Para posicionar la imagen */
  /* Otras propiedades de estilo */
  max-width: 20em;
  max-height: 20em;
   overflow: hidden;
}

.neon-border-pixel {
  border: 0.5em solid #000;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.8);
  background: black;
  /*background-image: url('https://www.blockhorseracing.com/image/pantalla_pixel/pantalla0.png');*/
  background-size: cover;
  background-position: center;
  width: 100%;

  display: inline-grid; /* Ajuste del div al tamaño de la imagen */
  overflow: hidden;
  object-fit: contain;

  /* Añadir transparencia */
  opacity: 0.8; /* Puedes ajustar el valor según lo transparente que desees que sea */
}



.neon-border-pixel-left {
  border: 0.5em solid #000;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.8);
  background: black;
  /*background-image: url('https://www.blockhorseracing.com/image/pantalla_pixel/pantalla0.png');*/
  background-size: cover;
  background-position: center;
  max-width: 20em;
  max-height: 20em;
  display: inline-grid; /* Ajuste del div al tamaño de la imagen */
  overflow: hidden;
  object-fit: contain;
  
}



.neon-border-pixel-left img {
  /*max-width: em;*/
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ajuste de la imagen al tamaño del contenedor */
}

.neon-border-pixel   img {
  /*max-width: em;*/
  width: 100%;
  height: 100%;
  object-fit: contain; /* Ajuste de la imagen al tamaño del contenedor */
   overflow: hidden;

}

.neon-border-pixel p {
  display: inline-block;
  margin: 0;
  padding: 0;
  object-fit: contain;
  animation: moveLeft 10s linear infinite; /* Ajusta la duración según lo necesites */

}

@keyframes moveLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.OwnerCard {
  background-color: #222; /* Fondo oscuro */
  border: 1px solid transparent; /* Borde transparente */
  border-image: linear-gradient(135deg, #ffd700, #ff00ff); /* Efecto de borde con degradado dorado */
  border-image-slice: 1; /* Configuración para el efecto de borde */
  padding: 0px; /* Espacio interior */
max-width: 40%;
}


/* Añadir un borde negro */
.neon-border:after {
  /*content: '';*/
  position: absolute;
  top: -6px;
  left: -6px;
  right: -6px;
  bottom: -6px;
  /*border: 6px solid #000;*/
}



.neon-border img{
 max-width: 35%;
 padding-right: 0% ;
 overflow: hidden;
}

.pantalla-neon-boton{
 float: right; 
padding: 0.5em;
margin-bottom: -1 auto;

}


.pantalla-neon-boton {
  position: inherit;
  bottom: 0;
  right: 0;
  margin: 1em; /* Asegura que el posicionamiento sea relativo al contenedor */
background-color: goldenrod;}

.pantalla-neon-boton button {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 2em; /* Agrega margen si lo necesitas */
  color: blue;
  background-color: #ffffff;
}


button .pantalla-neon-boton  {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 2em; /* Agrega margen si lo necesitas */
  color: #ffff;
  background-color: #ffffff;
}


column-span{
  font-size: 0.65em;
  color: #03a9f4;
}

row-span{
  font-size: 0.75em;
  color: goldenrod;

}


.imagen-caballos {
  display: flex;
  width: 100%;
}

.imagen-caballos div {
  width: 50%;
  box-sizing: border-box;
  padding: 20px;
}

.imagen-caballos img {
  max-width: 100%;
  height: auto;
}
/* Estilos para pantallas grandes */
@media (max-width: 768px) {
  .advertising-screens {
    flex-direction: column;
  }

  .screen {
    width: 90%;
  }
}
