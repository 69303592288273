/* src/css/JornadaSearch.css */
.jornada-search {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Alinea los elementos a la derecha */
  margin-bottom: 20px;
  width: 100%;
}

.jornada-search p {
  font-weight: bold;
  white-space: nowrap;
}

.search-input-container {
  position: relative;
  width: 150px; /* Ajusta este valor según tus necesidades */
}

.search-input {
  width: 100%;
  padding: 8px 30px 8px 8px; /* Ajustado para dar espacio al botón */
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,.25);
}

.search-button {
  position: absolute;
  right: 20px;
  top: 60%;
  transform: translateY(-50%);
  background: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #007bff;
  padding: 4px 8px;
  z-index: 10;
}

.search-button:hover {
  color: #0056b3;
}



/* Responsive design */
@media (max-width: 450px) {

.search-button {
  right: 10px;
  top: 60%;
  background: white;
  cursor: pointer;
}


}

