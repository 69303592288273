p.Status {
  position: fixed;
  grid-area: status;
  align-self: start;
  justify-self: start;
  background: #2e2e2e;
  font-weight: bold;
  border-radius: 5em;
  font-size: 1em; /* Tamaño más pequeño para móviles */
  font-style: italic;
  text-shadow: 
    1px -1px 0 #767676, 
    -1px 2px 1px #737272, 
    -2px 4px 1px #767474, 
    -3px 6px 1px #787777, 
    -4px 8px 1px #7b7a7a, 
    -5px 10px 1px #7f7d7d;
  color: darkred;
  text-align: left;
}

p.Tipo { 
  position: relative;
  writing-mode: vertical-rl;
  text-orientation: upright;
  white-space: nowrap;
  grid-area: tipo;
  align-self: flex-start;
  justify-self: center;
  text-shadow: 1px 1px 0 #ffd700;
  font-weight: bold;
  background: #2e2e2e;
  border-radius: 1em;
  font-size: 0.6em; /* Más pequeño en móviles */
  font-style: italic;
  text-shadow: 
    1px 1px 0 #ffd700, 
    -1px 2px 1px #737272, 
    -2px 4px 1px #767474;
  color: blue;
  margin-left: 1.5em;
}

.HorseImage {
  grid-area: image;
  max-width: 100%;
  object-fit: cover;
}

.trapecio-inforace {
  grid-area: footer;
  align-self: end;
  justify-self: start;
  width: max-content;
  border-top: 0.5em solid white;
  border-bottom: 0.5em solid #222;
  background-color: goldenrod;
  border-right: 1em solid transparent;
  border-left: 3em solid #222;
  padding-bottom: 0.1em;
  margin-top: -2em;
  margin-bottom: 1em;
  z-index: 10;
}

.trapecio-inforace div {
  display: inline-block;
  background-color: #497ba6;
}

.trapecio-inforace svg {
  width: 1.2rem;
  height: 1.2rem;
  fill: white;
}

.minteo-race {
  position: absolute;
  z-index: 15;
  bottom: 2em;
  left: 0%;
  object-fit: cover;
  background: radial-gradient(circle, #F5F5F5, #F8F8FF);
  border-radius: 50%;
  border: 3px solid #000000;
  box-sizing: border-box;
  width: 3em;
  justify-content: center;
}

.minteo-race p {
  position: relative;
  color: black;
  font-size: 0.7em;
  font-weight: bold;
}

.minteo-race h2 {
  position: relative;
  color: black;
  font-size: 1.2em; /* Reducido en móviles */
  font-weight: bold;
}









/* Estilos predeterminados para pantallas pequeñas (móviles primero) */

/* Tablets pequeñas y móviles grandes (mínimo 576px) */
@media screen and (min-width: 406px) {

  p.Status {
    font-size: 1em; /* Aumenta el tamaño de fuente */
  }

  p.Tipo { 
    font-size: 0.8em; /* Aumenta el tamaño de fuente */
    align-self: flex-start;
  justify-self: flex-start;
  }

  .minteo-race h2 {
    font-size: 1.2em; /* Aumenta el tamaño en pantallas más grandes */
  }



.trapecio-inforace {
  width: 60%;
  border-left: 4em solid #222;
}






}



/* Tablets pequeñas y móviles grandes (mínimo 576px) */
@media screen and (min-width: 576px) {
  /* Estilos para pantallas más grandes que 576px */


}

/* Tablets y pantallas medianas (mínimo 768px) */
@media screen and (min-width: 768px) {
  /* Estilos para pantallas más grandes que 768px */
    p.Status {
    font-size: 1em; /* Aumenta el tamaño de fuente */
  }

  p.Tipo { 
    font-size: 0.8em; /* Aumenta el tamaño de fuente */
    align-self: flex-start;
    justify-self: flex-start;
  }

  .minteo-race h2 {
    font-size: 1.2em; /* Aumenta el tamaño en pantallas más grandes */
  }



.trapecio-inforace {
  width: 50%;
  border-left: 4em solid #222;
}
}

/* Laptops pequeñas (mínimo 992px) */
@media screen and (min-width: 992px) {
  /* Estilos para pantallas más grandes que 992px */
}

/* Laptops grandes y pantallas de escritorio (mínimo 1200px) */
@media screen and (min-width: 1200px) {
  /* Estilos para pantallas más grandes que 1200px */
}

/* Pantallas muy grandes (mínimo 1600px) */
@media screen and (min-width: 1600px) {
  /* Estilos para pantallas más grandes que 1600px */
}
