/* src/components/EquineBasicInfo.css */

.equine-basic-info {
  font-family: 'Roboto', sans-serif;
  color: #e0e4e8;
  text-align: center;
}

.equine-basic-info .equine-id {
  font-weight: bold;
  color: #e0e4e8;
  margin: 5px 0;
}

.equine-basic-info .equine-image {
  border-radius: 50%;
  border: 2px solid #2b4b89;
  margin: 10px 0;
  width: 80px;
  height: 80px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.equine-basic-info .equine-name {
  font-size: 0.9rem;
  font-weight: 600;
  color: #ffd700;
  margin-top: 0.5em;
}
