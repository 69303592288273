/* Estilos generales para la sección de premios */
.section.premios {
   display: flex;
  flex-direction: column;
  align-items: center;
  border: 2px solid transparent;
  background-clip: padding-box;
  position: relative;
  padding: 0.2rem;
  width: 90%;
  overflow: hidden;
  border-radius: 10px;
}

.premios-title {
 font-size: 1.2em;
  font-weight: bold;
  color: #fbbf24;
top: 0px;
right: 0px;
}

/* Contenedor para mostrar las tarjetas en línea */
.premios-row-container {
  display: flex;
  justify-content: space-around;
  gap: 20px; /* Espacio entre las tarjetas */
  flex-wrap: wrap; /* Permite que las tarjetas se ajusten en pantallas pequeñas */
}

/* Estilos de las tarjetas */
.premios-card {
  background-color: #334155;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease;
  width: 27%; /* Cada tarjeta ocupa aproximadamente un tercio del contenedor */
}

.premios-card.open {
  width: 100%;
  padding: 0.5em;
  background-color: #2b3e4c; /* Color más oscuro al expandirse */
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
  padding: 0.1em;
  color: #fbbf24;
}

.card-body {
  padding: 10px;
}

.premios-row {
  display: flex;
  justify-content: space-around;
  padding: 5px 0;
  font-size: 1.2em;
}

.arrow {
  font-size: 1.5em;
  transition: transform 0.3s ease;
}

.premios-card.open .arrow {
  transform: rotate(180deg);
}

.premios-row div {
  width: 30%;
}
