.link-initlight {
  display: grid;
  justify-content: end;
 
  background-color:#167CBB;
 margin-left: 10vh;
 margin-right: 10vh;

}




.link-initlight ul {
  display: flex;
  padding: 0;

  align-items: center;
   background-color:#167CBB;
}

.link-initlight li {
 list-style-type: none;

  margin: 0 1rem;

}

.link-initlight li a {
 text-decoration: none;

  display: block;
  width: 100%;
}



.link-initdark {
  display: grid;
  justify-content: end;
 

  /*background-color:#423F3F;*/

  margin-left: 10vh;
 margin-right: 10vh;

}




.link-initdark ul {
  display: flex;
  padding: 0;

  align-items: center;
   background-color:#423F3F;

}
.link-initdark li {
  
  list-style-type: none;

  margin: 0 1rem;
}
.link-initdark li a {

  text-decoration: none;
  display: block;
  width: 100%;
}







.button-on {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  padding: 0.7em 1.4em 0.7em 1.1em;
  color: white;
  background: #ad5389;
  background: linear-gradient(0deg, rgba(20,167,62,1) 0%, rgba(102,247,113,1) 100%);
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #14a73e98;
  letter-spacing: 0.05em;
  border-radius: 20em;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-on:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #14a73e98;
}

.button-on:active {
  box-shadow: 0 0.3em 1em -0.5em #14a73e98;
}




@media screen and (max-width: 768px) {


.link-initlight {
  display: grid;
  justify-content: end;
 
  background-color:#167CBB;
 margin-left: 1vh;
 margin-right: 1vh;

}

.link-initdark {
  display: grid;
  justify-content: end;
 

  /*background-color:#423F3F;*/

  margin-left: 1vh;
 margin-right: 1vh;

}

}