.carreras-container {
  text-align: center;
  padding-top: 2%;
  z-index: 10;
  background-image: url(https://www.blockhorseracing.com/image/fondos/race.png);
  background-size: cover;
  background-position: center;
}

.carreras-container h1 {
  color: #167CBB;
  font-size: 1.5em;
}

.race-list {
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.no-data {
  text-align: center;
  padding: 1rem;
  color: #777;
  font-style: italic;
}

.filtro-race-button {
  background-color: rgba(0, 25, 0, 0.8);
  border: 2px solid #167CBB;
  color: white;
  font-size: 1em;
  padding: 1.5em 2em;
  cursor: pointer;
  transition: 0.3s;
  font-family: Arial, sans-serif;
  margin: 1em;
}

.filtro-race-button:hover {
  background-color: #167CBB;
  color: #000;
}

.pick-six-button {
  background-color: #1E3A8A;
  color: white;
  padding: 15px 30px;
  border: 2px dashed #00FFFF;
  border-radius: 15px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.3s;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.6), 0 0 30px rgba(0, 255, 255, 0.4);
}

.pick-six-button:hover {
  box-shadow: 0 0 25px rgba(0, 255, 255, 1), 0 0 50px rgba(0, 255, 255, 0.8);
  transform: scale(1.05);
}

.pick-six-button:active {
  background-color: #10B981;
  box-shadow: 0 0 15px rgba(16, 185, 129, 0.6), 0 0 30px rgba(16, 185, 129, 0.4);
  transform: scale(0.95);
}


.jornada-info-button {
  background-color: #1E3A8A;
  color: white;
  padding: 0.2em 0.5em;
  border: 2px dashed darkred;
  border-radius: 15px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.3s;
  box-shadow: 0 0 15px rgba(0, 255, 255, 0.6), 0 0 30px rgba(0, 255, 255, 0.4);
}

.jornada-info-button:hover {
  box-shadow: 0 0 25px rgba(0, 255, 255, 1), 0 0 50px rgba(0, 255, 255, 0.8);
  transform: scale(1.05);
}

.jornada-info-button:active {
  background-color: #10B981;
  box-shadow: 0 0 15px rgba(16, 185, 129, 0.6), 0 0 30px rgba(16, 185, 129, 0.4);
  transform: scale(0.95);
}


.race-baselight,
.race-highlight {
  z-index: 10;
  font-weight: bold;
}

./*race-baselight {
  background-color: darkcyan;
}

.race-highlight {
  background-color: #167CBB;
}
*/
/*.carreras-container ul {
  list-style-type: none;
  padding: 5%;
  margin-bottom: 0;
}

.carreras-container li {
  padding: 1vp;
  margin-bottom: 5px;
  border-radius: 5px;
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.carreras-container li span,
.carreras-container li .name,
span.stado {
  font-weight: bold;
  flex: 1;
  text-align: center;
  color: #222;
  font-size: 1em;
}
*/
.carreras-container li:hover {
  background-color: mediumslateblue;
  color: #423F3F;
}

.carreras-container button.apoya {
  background-color: #14a73e98;
  border: none;
  border-radius: 25px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  color: gold;
  cursor: pointer;
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  padding: 0.6rem 1.8rem;
  transition: 0.5s;
}

.carreras-container button.apoya:hover {
  background-color: darkgoldenrod;
  color: black;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
  transform: scale(0.8);
}

.carreras-container button.apoya:active {
  animation: shrinkExpand 1s ease;
}

/* Circles */
.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-block;
  animation: pulse 1s infinite;
}

.green {
  background-color: green;
  border: 0.2rem solid darkgreen;
}

.yellow {
  background-color: yellow;
  border: 0.2px solid darkgoldenrod;
}

.red {
  background-color: red;
  border: 0.2rem solid darkred;
}

/* Media Query */
@media screen and (max-width: 768px) {
  .carreras-container h1 {
    font-size: 1.2em;
  }
  
  .carreras-container li {
    flex-direction: column;
    width: 90%;
  }
}

/* Popup */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
}
