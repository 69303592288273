/* Estilos para el contenedor del loader */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* 100% del ancho disponible del contenedor padre */
  height: 25%; /* Máxima altura de 3em */
  position: relative;
  overflow: hidden; /* Para evitar desbordamientos */
}

/* Estilo para el fondo */
.loader-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%; /* 100% del ancho disponible */
  height: 100px; /* Mantener la altura máxima en 3em */
  background: linear-gradient(135deg, #000, #fff); /* Fondo blanco y negro */
  background: linear-gradient(135deg, #f0f0f0, #a0a0a0); /* Gradiente blanco y gris */
  animation: waveEffect 2s linear infinite; /* Animación de ondas */
  border-radius: 5px; /* Bordes redondeados */
}


/* Estilo para el GIF del loader */
.loading-gifH {
  width: 50%; /* El ancho se adapta automáticamente */
  height: 100%; /* Mantener la altura máxima en 3em */ 
  border-radius: 10%; /* Borde radial (redondeado) */
}



/* Estilo para el texto "Loading..." */
.loadingH-text {
  position: absolute; /* Para superponer el texto encima del GIF */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.2em; /* Tamaño del texto ajustado */
  font-weight: bold;
  color: #FFFFFF; /* Color blanco para buen contraste */
  text-shadow: 
    0 0 10px #39ff14,  /* Sombra verde neón */
    0 0 20px #39ff14, 
    0 0 30px #39ff14,
    0 0 10px #00aaff,  /* Sombra azul neón */
    0 0 20px #00aaff;  /* Aumenta la profundidad de la sombra azul */
}


/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .loading-text {
    font-size: 1em;
  }
}
