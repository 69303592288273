.container-inventario-Alimentos {
  z-index: 10;
  /*background: linear-gradient(135deg, rgba(22, 124, 187, 0.3), rgba(213, 75, 75, 0.3), rgba(66, 63, 63, 0.3));*/
  background-image: url(https://www.blockhorseracing.com/image/fondos/inventario-implementos1.jpg);
  background-size: cover;  /* Añade esta propiedad para que la imagen se adapte y cubra el fondo */
  background-position: center;  /* Centra la imagen en el fondo */
  margin-left: 0vh;
  margin-right: 0vh;
}



.container-inventario-Implementos {
  z-index: 10;
  /*background: linear-gradient(135deg, rgba(22, 124, 187, 0.3), rgba(213, 75, 75, 0.3), rgba(66, 63, 63, 0.3));*/
  background-image: url(https://www.blockhorseracing.com/image/fondos/implementos4.jpg);
  background-size: cover;  /* Añade esta propiedad para que la imagen se adapte y cubra el fondo */
  background-position: center;  /* Centra la imagen en el fondo */
  margin-left: 0vh;
  margin-right: 0vh;
}


.containerDash {
    z-index: 10;
  /*background: linear-gradient(135deg, rgba(22, 124, 187, 0.3), rgba(213, 75, 75, 0.3), rgba(66, 63, 63, 0.3));*/
  background-image: url(https://www.blockhorseracing.com/image/backgroundballos/track2.jpg);
  background-size: cover;  /* Añade esta propiedad para que la imagen se adapte y cubra el fondo */
  background-position: center;  /* Centra la imagen en el fondo */
  margin-left: 0vh;
  margin-right: 0vh;
}
