.Premios {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

.Premios th {
  background-color: #167CBB;
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
}

.Premios td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
  font-size: 1.2em;
}

.Premios tr:nth-child(even) {
  background-color: #f2f2f2;
}

.Premios tr:hover {
  background-color: #dddddd;
}

.cell {
  width: 100%;
  overflow-x: auto;
}

.cell table {
  border-collapse: collapse;
  width: 100%;
}

.cell th, .cell td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  color: gold;
}

.cell tr:nth-child(even) {
  background-color: #f2f2f2;
}

.cell tr:hover {
  background-color: #dddddd;
}
