/* Extensión del CSS - No borrar */
/* RACE MENU CSS */
.race-menu-nav {
  padding: 1em;
  border: 1px solid blue;
  background: linear-gradient(90deg, transparent, #00f, transparent);
  background-size: 200% 100%;
  color: #00f;
  text-align: left;
  padding-left: 10%;
  animation: neonText 2s linear infinite;
}

.race-menu-nav h1 {
  color: goldenrod;
  font-size: 2em;
}

@keyframes neonText {
  0%, 100% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
}


.filtro-race-button {
  background-color: rgba(0, 25, 0, 0.8); /* color del fondo con opacidad reducida */
  border: 2px solid#167CBB; /* color del borde */
  color: white; /* color del texto */
  font-size: 0.9em;
  padding: 1.3em 1.7em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: 'Arial', sans-serif;
  margin: 1em;
}

.filtro-race-button:hover {
  background-color: #167CBB; /* color de fondo al pasar el ratón */
  color: #000000; /* color del texto al pasar el ratón */
}



/* Custom Alert CSS */
.custom-alert {
  position: fixed;
   border: solid goldenrod;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #167CBB;
  color: #212529;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

/* Media Queries para pantallas pequeñas */
@media screen and (max-width: 768px) {
  .race-menu-nav {
  	padding: 0.1em;
    padding-left: 5%; /* Ajuste para pantallas pequeñas */
  }
  .filtro-race-button {
  background-color: rgba(0, 25, 0, 0.8); /* color del fondo con opacidad reducida */
  border: 1px solid#167CBB; /* color del borde */
  color: white; /* color del texto */
  font-size: 0.7em;
  padding: 1em 1em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-family: 'Arial', sans-serif;
  margin: 1em;
}
/* Puedes ajustar el estilo según tus necesidades */
.pancarta-text {
  font-size: 1em;
}

.race-menu-nav h1 {
  color: goldenrod;
  font-size: 1em;
}
}
