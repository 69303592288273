/* ModalPremios.css */

.fases-ganadores {
  margin-top: 20px;
}

.grupo-ganadores-card {
  border: 2px solid #007bff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 20px;
  background-color: #f8f9fa;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

.equine-link {
  text-decoration: none;
  color: inherit;
}

.equine-card {
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.equine-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.15);
}

.equine-card span {
  margin-bottom: 5px;
  font-size: 0.9em;
}

.equine-card span:first-child {
  font-weight: bold;
  color: #333;
}
