/* Estos son tus estilos CSS */
/*
.cart-container .hidden {
  display: none;
  padding: 5em;
}*/
.utility-group-modalo {
  /* Estilos del contenedor principal */
  display: inline-grid; /* Corregido: Era fix, debería ser flex */
  flex-direction: column;
  align-items: center;
  margin-top: 20px; /* Ajusta según necesidades */
    overflow: auto;
  white-space: nowrap;
  scrollbar-width: auto; /* Ancho del scroll en navegadores que no admiten WebKit */
  scrollbar-color: rgba(33, 150, 243, 1) rgba(0, 0, 255, 0.5); /* Colores del scroll en navegadores que admiten WebKit */


}

.titulo-modalo {
  /* Estilos del título */
  display: flex;
  overflow-x: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: rgba(76, 79, 93, 1);
  border-radius: 10px;
}


.titulo-modalo {
  text-align: center;
}

.total-items {
  margin-top: 10px;
}

/* Agrega estilos según necesites para otras partes de tu aplicación */

.imagen-modal-market {
  /* Estilos del contenido de la imagen */
  /* Ajusta según necesidades */
}

.items-container {
  /* Estilos del contenedor de elementos */
  display: flex;
  overflow-x: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: rgba(76, 79, 93, 1);
  border-radius: 10px;
  margin-top: 10px; /* Ajusta según necesidades */
}

.inventario-container{
  /* Estilos del contenedor de elementos */
  display: flex;
  overflow-x: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-top: 10px; /* Ajusta según necesidades */
}

.inventario-container .hidden{
  /* Estilos del contenedor de elementos */
  display: none;
}



.item-market {
  /* Estilos de cada elemento */
  flex: 0 0 auto;
  margin-right: 1em;
  /* Ajusta según necesidades */
}

.icon {
  /* Estilos de la imagen */
  width: 3em;
  height: 3em;
  /* Ajusta según necesidades */
}
