@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.wrapp {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 100%));
  gap: 2rem;
  justify-content: center;
}


.wrappBallosMain {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 100%));
  gap: 2rem;
  justify-content: center;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 10vh;
 
}



 .wrapp .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0px 0;
}

.container .card {
  position: relative;
  min-width: 260px;
  height: 360px;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.2),
  inset -4px -4px 15px rgba(255, 255, 255, 0.1),
  4px 4px 15px rgba(0, 0, 0, 0.3), -4px -4px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 8px;
  transition: 0.5s;
}




.container .card:nth-child(1) .box .content input {
  background: #2196f3;
}

 .container .card:nth-child(2) .box .content input {
  background: #e91e63;
}

 .container .card:nth-child(3) .box .content input {
  background: #23c186;
}
 .container .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}


 .container .card .box:hover {
  transform: translateY(-50px);
}

 .container .card .box:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

 .container .card .box .content  {
  padding: 20px;
  text-align: center;
}



 .container .card .box .content .caja {
  position: relative;
  border-radius: 8px;
  border: 3px solid #12897A;
  width: 100%;
  background-size: cover;
}



.caja img {
  z-index: 0;
  position: absolute;
  width: 100%;
 border: 2px solid transparent;
 top: 2.2vh;
 overflow: hidden;
 }

.icono {
position: absolute;
background: white;
border-spacing: 3px;
page-break-after: 5px;
display: inline-block;
width: 10%;
height: 10%;
 border: 3px solid #ffffff;
}

.iconomi {
position: absolute;
background: white;
width: 10%;
height: 10%;
 border: 3px solid #ffffff;
}


.brightness {filter: brightness(250%);}
.contrast {filter: contrast(180%);}
.grayscale {filter: grayscale(100%);}
.huerotate {filter: hue-rotate(180deg);}
.invert {filter: invert(100%);}
.opacity {filter: opacity(50%);}
.saturate {filter: saturate(7);}
.sepia {filter: sepia(100%);}
.shadow {filter: drop-shadow(8px 8px 10px green);}


 .container .card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

 .container .card .box .content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

 .container .card .box .content p {
  font-size: 0.70rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}




 .container .card .box .content input{
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 0px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}


 .container .card .box .content input:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}