.modales {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex; /* Centrar vertical y horizontalmente */
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6); /* Fondo más oscuro pero transparente */
  backdrop-filter: blur(10px); /* Efecto de desenfoque en el fondo */
}

.modales-content {
  background: rgba(255, 255, 255, 0.1); /* Fondo semi-transparente */
  backdrop-filter: blur(15px); /* Efecto de vidrio esmerilado */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37); /* Sombra elegante */
  border-radius: 20px; /* Bordes redondeados */
  border: 1px solid rgba(255, 255, 255, 0.18); /* Bordes semi-translúcidos */
  padding: 20px;
  max-width: 80%;
  width: 90%;
  text-align: center;
  animation: fadeIn 0.5s ease; /* Animación de entrada */
}

.modales-content button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 18px;
  font-weight: bold;
  color: #FFC107;
  background: rgba(255, 255, 255, 0.2); /* Botón semi-transparente */
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.modales-content button:hover {
  background: rgba(255, 193, 7, 0.8); /* Efecto hover */
  color: #000;
}

.modales-content img {
  width: 15%;
  height: auto;
  margin: 5px;
  border-radius: 10px;
  transition: transform 0.3s ease; /* Efecto hover para las imágenes */
}

.modales-content img:hover {
  transform: scale(1.1); /* Zoom al pasar el mouse */
  box-shadow: 0 4px 20px rgba(255, 255, 255, 0.4); /* Sombra en hover */
}

.modales-content .modales-conten {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px; /* Espaciado entre elementos */
}

.modales-content h3 {
  font-size: 2rem;
  font-weight: bold;
  color: #FFC107;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px #FFC107; /* Glow */
  text-transform: uppercase;
  margin-bottom: 20px;
  animation: glow 2s infinite alternate; /* Animación de brillo */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px #FFC107;
  }
  to {
    text-shadow: 0 0 20px rgba(255, 255, 255, 1), 0 0 30px #FFC107;
  }
}

/* Ajustes responsivos */
@media (max-width: 576px) {
  .modales-content h3 {
    font-size: 1.5rem;
  }
  .modales-content img {
    width: 25%;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .modales-content h3 {
    font-size: 1.8rem;
  }
}

@media (min-width: 993px) {
  .modales-content h3 {
    font-size: 2rem;
  }
}
