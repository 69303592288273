@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.wrapp_inventario_cofres {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100%, 100%));
  gap: 2rem;
  justify-content: center;
}

.wrapp_inventario_cofres .container-inventario-cofres-base {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0px 0;
}

.container-inventario-cofres-base .card_cofres_inventario {
  position: relative;
  min-width: 260px;
  height: 360px;
  box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.2),
    inset -4px -4px 15px rgba(255, 255, 255, 0.1),
    4px 4px 15px rgba(0, 0, 0, 0.3), -4px -4px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 8px;
  transition: 0.5s;
}

.container-inventario-cofres-base .card_cofres_inventario .box-nft_cofres_inventario {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: block;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.container-inventario-cofres-base .card_cofres_inventario .box-nft_cofres_inventario:hover {
  transform: translateY(-50px);
}

.container-inventario-cofres-base .card_cofres_inventario .box-nft_cofres_inventario:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

.container-inventario-cofres-base .card_cofres_inventario .box-nft_cofres_inventario .content_cofres_inventario {
  padding: 35px;
  text-align: center;
}

.cajas_cofres_inventario {
  position: relative;
  border-radius: 8px;
  border: 3px solid #12897a;
  width: 100%;
  background-size: cover;
}

.cajas_cofres_inventario img {
  width: 100%;
  border: 2px solid transparent;
  top: 2px;
}

.cajas_cofres_inventario svg {
  width: 0.2rem;
  height: 0.2rem;
  fill: white;
}

.icono {
  position: absolute;
  background: white;
  border-spacing: 3px;
  page-break-after: 5px;
  margin: 5px;
  width: 55%;
  height: 55%;
  border: 3px solid #ffffff;
}

.cofres-inventario_disponibles {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-width: 300px;
  margin: 1rem auto;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px #ffffff;
  transition: box-shadow 0.3s ease-in-out;
}

.btn-1 {
  order: 1;
}

.btn-2 {
  order: 2;
}

.btn-3 {
  order: 3;
}

.cofres-inventario_disponibles button {
  position: relative;
}

.cofres-inventario_disponibles button:hover::before {
  z-index: 12;
  content: attr(data-text); /* obtiene el valor de data-text */
  position: absolute;
  bottom: 1px; /* ajusta la posición del texto */
  left: 10%; /* centra el texto horizontalmente */
  transform: translateX(-25%); /* centra el texto horizontalmente */
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #000;
}

.cofres-inventario_disponibles p {
  font-size: 0.8rem;
  font-weight: bold;
  color: #ffffff;
}

button[type="submit"] img {
  position: relative;
  width: 2em;
  height: 2em;
}

button[type="submit"] {
  padding: 0em;
  background-color: transparent;
  border: none;
  cursor: pointer; /* Agregar esto para cambiar el cursor a una mano */
  transition: background-color 0.3s ease; /* Agregar una transición para suavizar el cambio de color */
  margin: 0em;
}

button[type="submit"]:hover {
  background-color: #0099ff; /* Cambiar el color de fondo al pasar el puntero */
  border-radius: 20%;
}

button[type="button"] {
  position: relative;
  padding: 0em;
  background-color: transparent;
  border: none;
  cursor: pointer; /* Agregar esto para cambiar el cursor a una mano */
  transition: background-color 0.3s ease; /* Agregar una transición para suavizar el cambio de color */
  margin: 0px;
}

button[type="button"] img {
  width: 15px;
  height: 15px;
}

button[type="button"]:hover {
  background-color: #0099ff; /* Cambiar el color de fondo al pasar el puntero */
  border-radius: 20%;
}

.iconosvg {
  position: absolute;
  background: white;
  margin: 5px;
  width: 7.5%;
  height: 6.5%;
  border-radius: 45%;
}

.brightness {
  filter: brightness(250%);
}

.contrast {
  filter: contrast(180%);
}

.grayscale {
  filter: grayscale(100%);
}

.huerotate {
  filter: hue-rotate(180deg);
}

.invert {
  filter: invert(100%);
}

.opacity {
  filter: opacity(50%);
}

.saturate {
  filter: saturate(7);
}

.sepia {
  filter: sepia(100%);
}

.shadow {
  filter: drop-shadow(8px 8px 10px green);
}

.container-inventario-cofres-base .card_cofres_inventario .box-nft_cofres_inventario .content_cofres_inventario h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.container-inventario-cofres-base .card_cofres_inventario .box-nft_cofres_inventario .content_cofres_inventario h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.container-inventario-cofres-base .card_cofres_inventario .box-nft_cofres_inventario .content_cofres_inventario p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.container-inventario-cofres-base .card_cofres_inventario .box-nft_cofres_inventario .content_cofres_inventario input {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.container-inventario-cofres-base .card_cofres_inventario .box-nft_cofres_inventario .content_cofres_inventario input:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}

.container-inventario-cofres-base .card_cofres_inventario .box-nft_cofres_inventario .content_cofres_inventario .input-price {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.container-inventario-cofres-base .card_cofres_inventario .box-nft_cofres_inventario .content_cofres_inventario .input-price:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}
